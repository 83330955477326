import React from 'react';
import * as S from 'shared/styles/index.style';
import { Outlet } from 'react-router-dom';
import FormLogo from 'widgets/Form/FormLogo';

const FormLayout = () => {
  return (
    <S.FormLayout>
      <FormLogo />
      <Outlet />
    </S.FormLayout>
  );
};

export default FormLayout;
