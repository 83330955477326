import { colors } from 'shared/consts/color';
import styled from 'styled-components';

export const SelectBoxContainer = styled.div`
  position: relative;
`;

export const SelectBoxLayout = styled.div`
  position: relative;
  width: 25rem;
`;

export const SelectBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
  border-radius: 8px;
  border: 1px solid ${colors.WarmGray[200]};
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;

  img {
    width: 1rem;
  }
`;

export const SelectOption = styled.ul`
  position: absolute;
  background-color: ${colors.WarmGray[25]};
  border: 1px solid ${colors.WarmGray[200]};
  border-radius: 8px;
  padding: 0.5rem;
  width: 100%;

  li {
    padding: 0.5em;
    cursor: pointer;
    border-radius: 8px;
  }
  li:hover {
    background-color: ${colors.Primary[100]};
    color: ${colors.Primary.Primary};
  }
`;
