type userInputType = {
  email: string | undefined;
  username: string | undefined;
  password: string | undefined;
  passwordCheck: string | undefined;
};

export const userInputData: userInputType = {
  email: '',
  username: '',
  password: '',
  passwordCheck: '',
};
