import React from 'react';
import LoadingIndicator from 'widgets/loadingIndicator/LoadingIndicator';
import * as S from 'shared/styles/index.style';

const Loading = () => {
  return (
    <S.ModalBackGround>
      <LoadingIndicator title="Verifying..." />
    </S.ModalBackGround>
  );
};

export default Loading;
