import { serverURL } from 'shared/endpoint/serverUrl';
import axios from 'axios';

const headerContent = {
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache, no-store,max-age=0, must-revalidate', // 캐시 방지 헤더 추가
  Pragma: 'no-cache', // HTTP/1.0 캐시 방지
  Expires: '0', // 캐시 만료
};

export const fetchPublicKey = async () => {
  try {
    const fetchData = await axios.get(`${serverURL}/security/rsa`, {
      headers: headerContent,
      withCredentials: true,
    });
    const {
      data: { base64PublicKey },
    } = fetchData;

    return base64PublicKey;
  } catch (error) {
    console.log(error);
    return '';
  }
};
