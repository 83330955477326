import axios from 'axios';
import { serverURL } from 'shared/endpoint/serverUrl';
import { accessToken } from 'shared/token/jwtToken';

export const fetchGetRepo = async () => {
  try {
    const repos = await axios.get(`${serverURL}/repository`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: '*/*',
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    });

    return repos;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const fetchGetDetailRepo = async (id: number) => {
  try {
    const repo = await axios.get(`${serverURL}/repository/${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: '*/*',
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    });
    console.log(repo);
    return repo;
  } catch (error) {
    console.log(error);
    return {};
  }
};
