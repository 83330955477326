import React, { useEffect, useState } from 'react';
import Title from 'widgets/title/PageTitle';
import BasicRoundButton from 'widgets/button/BasicRoundButton';
import { SuperImposeBox } from 'shared/styles/widget/ModelBox.style';
import UploadSection from './UI/UploadSection';
import Modal from './UI/Modal';
import ReferenceData from './UI/ReferenceData';
import { colors } from 'shared/consts/color';
import { useAtom } from 'jotai';
import { fileAtom } from 'app/jotai/fileState';
import LoadingIndicator from 'widgets/loadingIndicator/LoadingIndicator';
import * as S from 'shared/styles/index.style';
import Loading from './UI/Loading';

const STLVerification = () => {
  const [outerScanModel, setOuterScanModel] = useState('') as any;
  const [innerScanModel, setInnerScanModel] = useState('') as any;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstClick, setIsFirstClick] = useState(true);
  const [filesState, setFilesState] = useAtom(fileAtom);

  const openModal = () => {
    if (isFirstClick) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
        setIsModalOpen(true);
        setIsFirstClick(false);
      }, 3000);
    } else {
      setIsModalOpen(true);
    }
  };
  const isLoaded = filesState.length
    ? filesState?.reduce((acc: any, cur: boolean) => acc && cur)
    : false;

  useEffect(() => {
    setFilesState([]);
  }, []);

  return (
    <main>
      {isLoading && <Loading />}
      <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      <Title name="Verification" />
      <ReferenceData />
      <SuperImposeBox>
        <BasicRoundButton
          name="Superimpose"
          buttonColor={isLoaded ? undefined : colors.WarmGray[400]}
          action={isLoaded ? openModal : () => undefined}
        />
      </SuperImposeBox>
      <UploadSection
        outerScanModel={outerScanModel}
        innerScanModel={innerScanModel}
        setOuterScanModel={setOuterScanModel}
        setInnerScanModel={setInnerScanModel}
      />
    </main>
  );
};

export default STLVerification;
