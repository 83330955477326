import React from 'react';

const OptionList = ({
  name,
  icon,
  action,
}: {
  name: string;
  icon: string;
  action: any;
}) => {
  return (
    <li onClick={action}>
      <div>{name}</div>
      <img src={icon} />
    </li>
  );
};

export default OptionList;
