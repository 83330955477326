import React, { useState } from 'react';
import * as S from 'shared/styles/index.style';
import RepoEntity from './UI/RepoEntity';
import Menu from './UI/Menu';
import RepoList from './UI/RepoList';

const RepositoriesPage = () => {
  const [isAllRepoChecked, setIsAllRepoChecked] = useState(false);

  return (
    <S.RepositoriesSection>
      <Menu />
      <S.DashBordBox>
        <S.RepositoryListBox>
          <RepoEntity
            checkedControl={{
              isAllRepoChecked,
              setIsAllRepoChecked,
            }}
          />
          <RepoList
            checkedControl={{
              isAllRepoChecked,
              setIsAllRepoChecked,
            }}
          />
        </S.RepositoryListBox>
      </S.DashBordBox>
    </S.RepositoriesSection>
  );
};

export default RepositoriesPage;
