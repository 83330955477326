import { colors } from 'shared/consts/color';
import styled from 'styled-components';

export const RepositoryList = styled.ul<{ $checkedrepo: boolean }>`
  display: grid;
  grid-template-columns: 0.5fr 2fr 1fr 1fr 1fr 1fr;
  text-align: center;
  align-items: center;
  padding: 1.25em 3em 1.5em;
  background-color: ${props =>
    props.$checkedrepo ? colors.Primary[25] : 'transparent'};
  cursor: pointer;
`;

/** Name Row */
export const NameRowItem = styled.li`
  display: flex;
  align-items: center;

  img {
    height: 3em;
    margin-right: 1.25em;
  }
`;

export const NameBox = styled.div`
  text-align: start;

  p {
    font-size: 1.25em;
    color: ${colors.WarmGray[800]};
    margin-bottom: 0.25em;
    font-weight: 600;
  }

  span {
    font-size: 1em;
    font-weight: 200;
    color: ${colors.WarmGray[600]};
  }
`;

/** Date Row */
export const DateRowItem = styled.li`
  span {
    font-size: 1em;
    font-weight: 400;
    color: ${colors.WarmGray[600]};
  }
`;

/** Verification Row */
export const VerificationRowItem = styled.li`
  img {
    display: block;
    height: 1.5em;
    margin: 0 auto;
  }
`;

/** Payment Row */
export const PaymentRowItem = styled.li`
  img {
    display: block;
    height: 1.5em;
    margin: 0 auto;
  }
`;

/** Option Row */
export const OptionBtnRowItem = styled.li`
  img {
    display: block;
    height: 1.5em;
    margin: 0 auto;
  }
  cursor: pointer;
`;
