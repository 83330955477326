export const validateEmail = (email: string) => {
  if (email) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // 유효한 이메일 형식인지 검사
    if (re.test(email)) return { isCorrect: true, errorMessage: '' };
    else
      return { isCorrect: false, errorMessage: '이메일이 유효하지 않습니다.' };
  } else {
    return { isCorrect: false, errorMessage: '이메일을 입력해주세요' };
  }
};

export const validateName = (name: string) => {
  if (name) {
    if (name.length >= 2) {
      return { isCorrect: true, errorMessage: '' };
    } else {
      return {
        isCorrect: false,
        errorMessage: '이름은 2자 이상이어야 합니다.',
      };
    }
  } else {
    return { isCorrect: false, errorMessage: '이름을 입력해주세요' };
  }
};

export const validatePassword = (password: string, passwordCheck: string) => {
  if (password.length >= 8) {
    if (password !== passwordCheck)
      return { isCorrect: false, errorMessage: '비밀번호를 확인해주세요.' };
    else return { isCorrect: true, errorMessage: '' };
  }
  return {
    isCorrect: false,
    errorMessage: '비밀번호는 8자 이상이어야 합니다.',
  };
};
