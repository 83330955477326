import React from 'react';
import * as S from 'shared/styles/index.style';
import { SmallFileIcon } from 'assets/icon';
import UploadingList from './uploadingBox/UploadingList';

const UploadingBox = ({ Files }: { Files: any }) => {
  return (
    <S.UploadingBox>
      <p>Files</p>
      {Files?.length ? (
        <div>
          {Files?.map((obj: { name: string; size: number }, index: number) => {
            const sizeInMB = Number((obj?.size / (1024 * 1024))?.toFixed(2));
            return (
              <UploadingList
                index={index}
                key={index}
                fileName={obj?.name}
                fileSize={sizeInMB}
              />
            );
          })}
        </div>
      ) : (
        <S.UploadingList>
          <S.UploadingItem>
            <img className="fileIcon" src={SmallFileIcon} />
            <span className="queueText">Please Uploading .stl files</span>
          </S.UploadingItem>
        </S.UploadingList>
      )}
    </S.UploadingBox>
  );
};

export default UploadingBox;
