import React from 'react';

const Option = ({ icon, name }: { icon: string; name: string }) => {
  return (
    <li>
      <img src={icon} />
      <a href="">{name}</a>
    </li>
  );
};

export default Option;
