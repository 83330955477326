import React from 'react';
import { useLocation } from 'react-router-dom';
import { colors } from 'shared/consts/color';
import NavigationBar from 'widgets/navigation/NavigationBar';
import * as S from '../../shared/styles/index.style';
import { Outlet } from 'react-router-dom';
const ThreePageLayout = () => {
  return (
    <S.AppBox>
      <NavigationBar />
      <S.TemplateBox
        templateboxbgcolor={colors.WarmGray[200]}
        templateMargin={'15%'}
      >
        <Outlet />
      </S.TemplateBox>
    </S.AppBox>
  );
};

export default ThreePageLayout;
