import React from 'react';
import * as S from 'shared/styles/index.style';

const BasicRoundButton = ({
  name,
  action,
  buttonColor,
  textColor,
  borderColor,
}: {
  name: string;
  action?: () => void;
  buttonColor?: string;
  textColor?: string;
  borderColor?: string;
}) => {
  return (
    <S.BasicRoundButton
      $buttonColor={buttonColor}
      $textColor={textColor}
      $borderColor={borderColor}
      onClick={action}
    >
      {name}
    </S.BasicRoundButton>
  );
};

export default BasicRoundButton;
