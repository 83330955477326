import { fetchPublicKey } from 'features/RSA/rsaAPI';
import JSEncrypt from 'jsencrypt';
import forge from 'node-forge';

// JSEncrypt
export const encodePassword = async (password: string) => {
  try {
    const rsaPublicKey = await fetchPublicKey(); // 잘 받아옴

    const encrypt = new JSEncrypt();

    const pemKey = `-----BEGIN PUBLIC KEY-----\n${rsaPublicKey}\n-----END PUBLIC KEY-----`;
    encrypt.setPublicKey(pemKey);
    const encodedPassword = encrypt.encrypt(password);
    console.log('rsaPublicKey', rsaPublicKey);
    console.log('typeof RsaPublicKey', typeof rsaPublicKey);
    console.log(pemKey);
    console.log('encodedPassword', encodedPassword);
    return encodedPassword;
  } catch (error) {
    console.log(error);
  }
};

// forge
export const encryptPassword = async (password: string) => {
  try {
    const rsaPublicKey = await fetchPublicKey(); // 잘 받아옴
    const pemKey = `-----BEGIN PUBLIC KEY-----\n${rsaPublicKey}\n-----END PUBLIC KEY-----`;
    const publicKey = forge.pki.publicKeyFromPem(pemKey);
    const encrypted = publicKey.encrypt(
      forge.util.encodeUtf8(password),
      'RSA-OAEP',
    );
    const encryptedPassword = forge.util.encode64(encrypted);
    console.log('rsaPublicKey', rsaPublicKey);
    console.log(pemKey);
    console.log('encodedPassword', encryptedPassword);
    return encryptedPassword;
  } catch (error) {
    console.log(error);
  }
};

export const encryptPublicKey = async (
  password: string,
  publicKey?: string,
) => {
  const encrypt = new JSEncrypt();
  const rsaPublicKey = await fetchPublicKey(); // 잘 받아옴
  console.log(rsaPublicKey);
  if (publicKey) {
    console.log('inputPublicKey', publicKey);
    const pemKey = `-----BEGIN PUBLIC KEY-----\n${publicKey}\n-----END PUBLIC KEY-----`;
    encrypt.setPublicKey(pemKey);

    const encodedPassword = encrypt.encrypt(password);
    console.log(encodedPassword);
    return encodedPassword;
  }
  if (rsaPublicKey) {
    console.log('fetchPublicKey', rsaPublicKey);
    const pemKey = `-----BEGIN PUBLIC KEY-----\n${rsaPublicKey}\n-----END PUBLIC KEY-----`;
    encrypt.setPublicKey(pemKey);

    const encodedPassword = encrypt.encrypt(password);
    console.log(encodedPassword);
    return encodedPassword;
  }
  return false;
};
