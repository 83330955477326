import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as S from 'shared/styles/index.style';
import BasicButton from 'widgets/button/BasicButton';
import IconButton from 'widgets/button/IconButton';
import Title from 'widgets/title/PageTitle';
import { TrashIcon } from 'assets/icon';
import { useAtom } from 'jotai';
import { checkedReposAtom } from 'app/jotai/checkedRepoState';
import { deleteRepo } from '../api/deleteRepo';
import { objectAtom } from 'app/jotai/objectState';
import { fileAtom } from 'app/jotai/fileState';

const RepoTitle = () => {
  const navigate = useNavigate();
  const [checkedRepo, _] = useAtom(checkedReposAtom);
  const [objectState, setObjectState] = useAtom(objectAtom);
  const [filesState, setFilesState] = useAtom(fileAtom);

  const clickNewRepo = () => {
    setObjectState({ isCreated: false, type: '' }); // 초기화
    setFilesState([]);
    navigate('/new-repository');
  };

  return (
    <S.PageTitleBox>
      <Title name="Repositories" />
      <S.ButtonBox>
        <BasicButton name="New Repo" size="small" action={clickNewRepo} />
        <IconButton icon={TrashIcon} action={() => deleteRepo(checkedRepo)} />
      </S.ButtonBox>
    </S.PageTitleBox>
  );
};

export default RepoTitle;
