import { geOptionIcon, MenuIcon } from 'assets/icon';
import React from 'react';
import Option from 'widgets/optionList/Option';
import OptionLayout from 'widgets/optionList/OptionLayout';

const ExtractionOption = () => {
  return (
    <OptionLayout>
      <Option icon={MenuIcon} name="Menu" />
      <Option icon={geOptionIcon} name="Option" />
    </OptionLayout>
  );
};

export default ExtractionOption;
