import React from 'react';
import NavigationBar from 'widgets/navigation/NavigationBar';
import * as S from '../../shared/styles/index.style';
import { Outlet } from 'react-router-dom';

const DashboardLayout = () => {
  return (
    <S.AppBox>
      <NavigationBar />
      <S.TemplateBox templateMargin={'18%'}>
        <Outlet />
      </S.TemplateBox>
    </S.AppBox>
  );
};

export default DashboardLayout;
