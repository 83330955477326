import React from 'react';
import { useLoader } from '@react-three/fiber';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import * as THREE from 'three';

const TeethModel = ({
  type,
  color,
  path,
  position,
  rotation,
}: {
  type?: string;
  color?: string;
  position?: any;
  rotation?: any;
  path: string;
}) => {
  const Gemometry = useLoader(STLLoader, `./stl_model/${path}.stl`);
  const vertices = Gemometry.attributes.position.array;
  const colors = new Float32Array(vertices.length); // 3 values per vertex (R, G, B)
  const colorAttribute = new THREE.BufferAttribute(colors, 3);
  colors.fill(1); // Fill with white color initially
  Gemometry.setAttribute('color', colorAttribute);

  for (let i = 0; i < vertices.length; i += 3) {
    colors[i] = 0;
    colors[i + 1] = 0.3;
    colors[i + 2] = 0;
  }

  // 랜덤 영역 선택 및 색상 변경
  const changeColorForRandomVertices = (rgb: Array<number>) => {
    // const numberOfVertices = vertices.length / 3;
    // const numberOfVerticesToChange = Math.floor(numberOfVertices * percentage);

    for (let i = 217000; i < 227000; i += 1) {
      // const vertexIndex = Math.floor(Math.random() * numberOfVertices) * 3;
      const [R, G, B] = rgb;
      if (i % 3 === 0) {
        colors[i] = R;
        colors[i + 1] = G;
        colors[i + 2] = B;
      }
    }
  };

  // 특정 영역을 빨간색, 파란색으로 변경 (예: 각각 10%)
  changeColorForRandomVertices([1, 0, 0]); //  [1,0,0]은 빨간색
  // changeColorForRandomVertices([0, 0, 1], 0.1); // 파란색
  return (
    <mesh geometry={Gemometry} position={position} rotation={rotation}>
      {type === 'verified' ? (
        <meshStandardMaterial vertexColors />
      ) : (
        <meshStandardMaterial color={color} />
      )}
    </mesh>
  );
};

export default TeethModel;
