export const colors = {
  White: '#ffffff',
  Black: '#000000',
  Red: `#B22929`,

  WarmGray: {
    25: '#FAFAFA',
    50: '#F5F5F6',
    100: '#F0F1F1',
    200: '#ECECED',
    300: '#CECFD2',
    400: '#94969C',
    500: '#85888E',
    600: '#61646C',
    700: '#333741',
    800: '#1F242F',
    900: '#161B26',
  },
  Primary: {
    25: '#E6F6F9',
    50: '#DAF2F6',
    100: '#B3E4EC',
    Primary: '#09A7C1',
    300: '#0896AE',
    400: '#07869A',
    500: '#077D91',
    600: '#056474',
    700: '#044B57',
    800: '#033A44',
  },
};
