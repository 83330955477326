import React from 'react';

/** images */
import { ProfileImg } from 'assets/icon';
import * as S from 'shared/styles/index.style';

import { useAtomValue } from 'jotai';
import { userAtom } from 'app/jotai/userState';

const Profile = () => {
  const userInfo = useAtomValue(userAtom);
  return (
    <S.ProfileContainer>
      <img src={ProfileImg} />
      <p>{userInfo.name}</p>
    </S.ProfileContainer>
  );
};

export default Profile;
