import React, { ReactElement } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import './transition.css';

const RouterTransition = ({
  children,
  locationKey,
}: {
  children: ReactElement | ReactElement[];
  locationKey: string;
}) => {
  return (
    <TransitionGroup>
      <CSSTransition key={locationKey} timeout={300} classNames="fade">
        {children}
      </CSSTransition>
    </TransitionGroup>
  );
};

export default RouterTransition;
