import React from 'react';
import * as S from 'shared/styles/index.style';
import BasicButton from 'widgets/button/BasicButton';
import CanvasLayout from 'widgets/3dModel/CanvasLayout';
import { ZoomInIcon } from 'assets/icon';
import TeethModel from 'widgets/3dModel/TeethModel';
import { useAtomValue } from 'jotai';
import { modelAtom } from 'app/jotai/modelState';
const CompleteModel = ({
  setIsModalOpen,
}: {
  setIsModalOpen: (state: boolean) => void;
}) => {
  const modelState = useAtomValue(modelAtom);
  const position = modelState.type === 'implant' ? [7, 0, 10] : [0, 0, 0];
  return (
    <S.PreviewBoxModel>
      <BasicButton
        icon={ZoomInIcon}
        size="small"
        action={() => setIsModalOpen(true)}
        position="absolute"
      />
      <CanvasLayout>
        {modelState?.model.map(str => {
          if (str === 'crown') {
            return <></>;
          }
          if (str === 'veneer') {
            return <></>;
          }
          if (modelState?.type === 'denture') {
            if (str === 'upper') {
              return (
                <TeethModel
                  path={`${modelState.type}/${str}`}
                  position={[0, -100, 0]}
                />
              );
            } else {
              return <TeethModel path={`${modelState.type}/${str}`} />;
            }
          } else {
            return (
              <TeethModel
                path={`${modelState.type}/${str}`}
                position={position}
              />
            );
          }
        })}
      </CanvasLayout>
    </S.PreviewBoxModel>
  );
};

export default CompleteModel;
