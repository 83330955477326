import React from 'react';
import * as S from 'shared/styles/index.style';
import { RealTeethLogo } from 'assets/logo';
import BasicButton from 'widgets/button/BasicButton';
import { colors } from 'shared/consts/color';
import { useNavigate } from 'react-router-dom';
import { ProfileImg } from 'assets/icon';
import { useAtomValue } from 'jotai';
import { userAtom } from 'app/jotai/userState';

const NavigationHeader = () => {
  const navigate = useNavigate();
  const userInfo = useAtomValue(userAtom);

  return (
    <S.NavHeaderBox>
      <S.HeaderLogo>
        <img src={RealTeethLogo} onClick={() => navigate('/')} />
      </S.HeaderLogo>
      <S.NavMenu>
        <a onClick={() => navigate('./repositories')}>Repositories</a>
        <a>Product</a>
        <a>Price</a>
        <a>Support</a>
      </S.NavMenu>
      {userInfo.email ? (
        <S.HomeHeader>
          <p>{userInfo.name}</p>
          <img src={ProfileImg} />
        </S.HomeHeader>
      ) : (
        <>
          <S.NavButton>
            <BasicButton
              name="Login"
              size="large"
              action={() => navigate('/login')}
            />
          </S.NavButton>
          <S.NavButton>
            <BasicButton
              name="Signup"
              size="large"
              buttonColor="transparent"
              textColor={colors.Primary.Primary}
              borderColor={colors.Primary.Primary}
              action={() => navigate('/signup')}
            />
          </S.NavButton>
        </>
      )}
    </S.NavHeaderBox>
  );
};

export default NavigationHeader;
