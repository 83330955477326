import { colors } from 'shared/consts/color';
import styled from 'styled-components';

export const NavParent = styled.div`
  position: fixed;
`;
export const NavContainer = styled.nav`
  position: fixed;
  width: 15%;
  height: 100vh;
  border: 1px solid ${colors.WarmGray[300]};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`;

export const NavBox = styled.div`
  padding: 0rem 2rem 2rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const LogoImgBox = styled.div`
  width: 100%;
  padding: 2.5em 2em 0 2em;

  img {
    display: block;
    width: 6.25em;
    padding-bottom: 5.25em;
  }
`;

export const MenueBox = styled.ul`
  li {
    width: fit-content;
    display: flex;
    align-items: center;
    padding: 0.5em 1em;
    margin-top: 1em;
  }

  .highlight {
    background-color: ${colors.WarmGray[25]};
    border-radius: 1.25em;
  }

  img {
    display: block;
    padding-right: 0.5em;
  }

  a {
    display: block;
    font-weight: 600;
  }
`;

export const OptionMenuBox = styled.ul`
  /* position: absolute; */
  /* bottom: 6.75em; */

  li {
    width: fit-content;
    display: flex;
    align-items: center;
    padding-top: 1em;
  }

  img {
    display: block;
    /* width: 1.5em; */
    height: 1.5em;
    padding-right: 0.5em;
  }

  a {
    display: block;
    font-size: 1em;
    color: #61646c;
    font-weight: 600;
  }
`;
