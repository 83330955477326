import React from 'react';

// image
import { HomeIcon, DataIcon, HelpIcon, RepoIcon } from 'assets/icon';
import { RealTeethLogo } from 'assets/logo';
import * as S from 'shared/styles/index.style';
import OptionMenuList from './OptionMenuList';
import { useLocation, useNavigate } from 'react-router-dom';
import Profile from './Profile';

const NavigationBar = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <S.NavContainer>
      <S.LogoImgBox>
        <img src={RealTeethLogo} onClick={() => navigate('/')} />
      </S.LogoImgBox>
      <S.NavBox>
        <S.MenueBox>
          <OptionMenuList
            icon={RepoIcon}
            title="Repositories"
            path="repositories"
            hightlight={pathname === '/repositories'}
          />
          <OptionMenuList
            icon={DataIcon}
            title="Data"
            path="data"
            hightlight={pathname === '/data'}
          />
        </S.MenueBox>
        <S.OptionMenuBox>
          <OptionMenuList icon={HomeIcon} title="Home" path="/" />
          <OptionMenuList icon={HelpIcon} title="Help" path="" />
        </S.OptionMenuBox>
      </S.NavBox>
      <Profile />
    </S.NavContainer>
  );
};

export default NavigationBar;
