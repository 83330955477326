import { colors } from 'shared/consts/color';
import { styled } from 'styled-components';
export const ModelBox = styled.div`
  width: 100%;
  height: 25em;
  border: 1px solid silver;
  margin-top: 3em;
  border-radius: 10px;
  border-color: ${colors.WarmGray[300]};
`;
export const ModelBoxTitle = styled.h2`
  position: absolute;
  z-index: 999;
  color: '${colors.WarmGray[700]};';
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0.875em;
`;

export const SuperImposeBox = styled.div`
  display: flex;
  justify-content: center;
`;
