import React from 'react';

import * as S from 'shared/styles/index.style';

const BasicCheckBox = ({
  isChecked,
  checkFunc,
}: {
  isChecked?: boolean;
  checkFunc: () => void;
}) => {
  return (
    <S.BasicCheckBox
      type="checkBox"
      onClick={checkFunc}
      checked={isChecked}
      readOnly
    />
  );
};

export default BasicCheckBox;
