import { colors } from 'shared/consts/color';
import styled from 'styled-components';
import { ButtonPropsType } from 'ButtonType';

export const IconButton = styled.button<ButtonPropsType>`
  display: block;
  width: 2.25rem;
  height: 2.25rem;
  border: none;
  border-radius: 8px;
  padding: 0;
  background: ${colors.White} url(${props => props.icon}) center no-repeat;
  cursor: pointer;
`;
