import styled from 'styled-components';

export const Header = styled.header`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 2.5em 0;
  z-index: 999;

  p {
    font-weight: 400;
    padding-right: 1em;
    font-size: 1.25em;
  }

  img {
    height: 3em;
  }
`;

export const HomeHeader = styled(Header)`
  padding: 0;
`;
