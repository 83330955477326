import React, { ReactElement } from 'react';
import * as S from 'shared/styles/index.style';

const OptionBoxLayout = ({
  children,
}: {
  children: ReactElement<any> | ReactElement | ReactElement[];
}) => {
  return (
    <S.ToggleOptionBox>
      <S.ToggleOptionListContainer>{children}</S.ToggleOptionListContainer>
    </S.ToggleOptionBox>
  );
};

export default OptionBoxLayout;
