import React, { useState } from 'react';
import * as S from 'shared/styles/index.style';
import SmallModalBox from 'widgets/modal/SmallModalBox';
import BasicButton from 'widgets/button/BasicButton';
import { colors } from 'shared/consts/color';
import SelectBox from '../selectBox/SelectBox';
import { useNavigate } from 'react-router-dom';
import useSaveRepo from 'pages/preview/model/useSaveRepo';

const SaveModal = ({
  setIsModalOpen,
}: {
  setIsModalOpen: (state: boolean) => void;
}) => {
  const [isToggleOpen, setIsToggleOpen] = useState(false);
  const [type, setType] = useState('Type');
  const [repoName, setRepoName] = useState('');
  const navigate = useNavigate();
  const { saveRepo } = useSaveRepo();

  const inputRepoName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRepoName(e.target.value);
  };
  const saveHandler = () => {
    saveRepo(repoName, type);
    navigate('/creation');
  };

  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      <S.ModalBackGround onClick={closeModal} />
      <SmallModalBox title="Save">
        <S.ModalInputContainer>
          <S.ModalInputTitle>Save Name</S.ModalInputTitle>
          <input
            placeholder="Repository Name"
            value={repoName}
            onChange={inputRepoName}
          />
        </S.ModalInputContainer>

        <S.ModalInputContainer>
          <S.ModalInputTitle>Type</S.ModalInputTitle>
          <SelectBox
            isToggleOpen={isToggleOpen}
            setIsToggleOpen={setIsToggleOpen}
            type={type}
            setType={setType}
          />
        </S.ModalInputContainer>
        <S.ModalButtonLayout>
          <BasicButton name="Save" size="large" action={saveHandler} />
          <BasicButton
            name="Cancel"
            size="large"
            buttonColor="transparent"
            textColor={colors.Primary.Primary}
            borderColor={colors.Primary.Primary}
            action={closeModal}
          />
        </S.ModalButtonLayout>
      </SmallModalBox>
    </>
  );
};

export default SaveModal;
