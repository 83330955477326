import { colors } from 'shared/consts/color';
import { styled } from 'styled-components';
import { BasicButton } from './BasicButton.style';

export const FormLayout = styled.div``;

export const FormLogo = styled.div`
  width: 100%;

  img {
    margin: 0 0 0 7.5em;
    margin-top: 3em;
    display: block;
    width: 7.5em;
    padding-bottom: 1.5em;
  }
`;

export const FormBox = styled.form`
  background-color: ${colors.White};
  width: 36rem;
  /* height: 42.25rem; */
  padding: 3.5rem;
  border-radius: 24px;
  margin: 0 auto;
  /* text-align: center; */
`;
export const FormTitle = styled.div`
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 0.25rem;
`;

export const FormSubText = styled.div`
  color: ${colors.WarmGray[400]};
  font-size: 1rem;

  span {
    color: ${colors.Primary.Primary};
    font-weight: bold;
    cursor: pointer;
  }
`;

export const FormHeader = styled.div`
  margin-bottom: 1rem;
`;

export const FormInputContainer = styled.div`
  display: flex;
  position: relative;
  /* margin-bottom: 2.5rem; */

  img {
    position: absolute;
    padding: 1em;
  }
`;
export const IconWrapper = styled.div`
  position: absolute;
  padding: 1em;
  svg path {
    transition: fill 0.3s; // 부드러운 색상 전환
  }
  svg rect {
    transition: fill 0.3s; // 부드러운 색상 전환
  }
`;

export const FormInputButton = styled(BasicButton)`
  position: absolute;
  padding: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  right: 1rem;
  top: 1em;
  font-weight: bold;
`;

export const FormInput = styled.input`
  width: 100%;
  border: none;
  border-radius: 12px;
  padding: 1.5em;
  /* margin-bottom: 1em; */
  border: 1px solid ${colors.WarmGray[400]};
  box-sizing: border-box;
  text-align: justify;
  padding-left: 3.5em;
  &:focus {
    outline: none;
    border: 1px solid ${colors.Primary.Primary};
    box-shadow: 0 0 0.2em 0.2em ${colors.Primary.Primary};
  }
  &:focus ~ ${IconWrapper} svg path {
    stroke: ${colors.Primary.Primary};
  }
  &:focus ~ ${IconWrapper} svg rect {
    stroke: ${colors.Primary.Primary};
  }
`;

export const FormErrorMessage = styled.div`
  font-size: 1rem;
  font-weight: bold;
  color: ${colors.Red};
  margin: 0.5rem 0rem 1rem 0.5rem;
`;

export const FormButton = styled.button<{
  $textColor: string;
  bgColor: string;
  border: boolean;
}>`
  position: relative;
  margin-top: 0.75em;
  width: 100%;
  padding: 1em;
  border-radius: 12px;
  border-radius: 1000px;
  background-color: ${props => props.bgColor};
  color: ${props => props.$textColor};
  border: ${props =>
    props.border ? `1px solid ${colors.WarmGray[400]}` : `none`};
  font-weight: bold;
  font-size: 1em;

  img {
    position: absolute;
    left: 1.125em;
    width: 1.5em;
  }
`;

export const LineWithText = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 1.75em;
  margin-bottom: 1.75em;
`;

export const FormLine = styled.hr`
  flex-grow: 1;
  border: 0;
  height: 1px;
  background-color: #000;

  &:first-child {
    margin-right: 0.5em;
  }

  &:last-child {
    margin-left: 0.5em;
  }
`;

export const FormLineText = styled.span`
  white-space: nowrap;
  padding: 0 1em;
  font-weight: bold;
  color: ${colors.WarmGray[600]};
`;
