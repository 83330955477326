import React, { ReactElement } from 'react';
import * as S from 'shared/styles/index.style';
import { userInputData } from '../../pages/signup/consts/userInputData';
import useInputs from '../../pages/signup/model/useInputs';

const FormInput = ({
  icon,
  placeholder,
  type,
  name,
  maxLength,
  inputHandler,
  buttonName,
}: {
  icon: ReactElement;
  placeholder: string;
  type?: string;
  name: string;
  maxLength: number;
  inputHandler?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  buttonName?: string;
}) => {
  return (
    <S.FormInputContainer>
      <S.FormInput
        type={type}
        placeholder={placeholder}
        name={name}
        onChange={inputHandler}
        maxLength={maxLength}
      />
      <S.IconWrapper>{icon}</S.IconWrapper>
      {buttonName && (
        <S.FormInputButton type="button">{buttonName}</S.FormInputButton>
      )}
    </S.FormInputContainer>
  );
};

export default FormInput;
