import React, { ReactElement } from 'react';
import * as S from 'shared/styles/index.style';

const ToolBoxLayout = ({
  children,
}: {
  children: ReactElement[] | ReactElement;
}) => {
  return <S.CreationSettingContainer>{children}</S.CreationSettingContainer>;
};

export default ToolBoxLayout;
