import React, { useState } from 'react';
import * as S from 'shared/styles/index.style';
import { STLFileIcon } from 'assets/icon';
import UploadingBox from './UploadingBox';
import { ModelFileType } from 'FileType';
import { modelAtom } from 'app/jotai/modelState';
import { useAtom } from 'jotai';
import { fileAtom } from 'app/jotai/fileState';

export default function ImageDropBox(dropBoxProps: {
  model: ModelFileType;
  setModel: (file: ModelFileType) => void;
}) {
  const { model, setModel } = dropBoxProps;
  const [__, setIsDragging] = useState(false);
  const [_, setModelState] = useAtom(modelAtom);
  const [___, setFilesState] = useAtom(fileAtom);

  const handleImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;

    if (files) {
      const type = files[0]?.name.split('-')[0];
      if (type === 'crown') {
        setModelState({
          model: ['upperjaw', 'lowerjaw', 'abutment', 'crown'],
          type: type,
        });
      }
      if (type === 'implant') {
        setModelState({
          model: ['upperjaw', 'lowerjaw', 'abutment', 'abutment2', 'crown'],
          type: type,
        });
      }
      if (type === 'denture') {
        setModelState({
          model: ['lower', 'upper'],
          type: type,
        });
      }
      if (type === 'laminate') {
        setModelState({
          model: ['upperjaw', 'veneer'],
          type: type,
        });
      }
      if (type === 'bridge') {
        setModelState({
          model: ['upperjaw', 'lowerjaw', 'crown'],
          type: type,
        });
      }

      setFilesState(new Array(files.length).fill(false));

      setModel([...files]);
    }
    e.target.value = ''; // After updating, reset the value to allow the same file to be selected again if needed.
  };

  const onDragEnter = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };
  const onDragLeave = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };
  const onDragOver = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files) {
      setIsDragging(true);
    }
  };
  const onDrop = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setModel([...e.dataTransfer.files]);
    setIsDragging(false);
  };

  return (
    <>
      <S.DropBox
        onDragEnter={onDragEnter} // 드래그된 요소가 드롭 대상에 들어갈 때 발생
        onDragLeave={onDragLeave} // 드래그된 요소가 드롭 대상을 벗어날 때 발생
        onDragOver={onDragOver} // 발생 드래그된 요소가 드롭 대상 위에 있을 때 발생
        onDrop={onDrop} // 드래그한 요소를 드롭 대상에 놓을 때 발생
      >
        <S.DropBoxList>
          <S.DropBoxItem>
            <img src={STLFileIcon} />
          </S.DropBoxItem>
          <S.DropBoxItem>
            <span>Drag and Drop Files to upload</span>
            <span>or</span>
          </S.DropBoxItem>
          <S.DropBoxItem>
            <label htmlFor="file">
              <span className="selectFileBtn">Select File to upload</span>
            </label>
            <input
              accept=".stl"
              type="file"
              id="file"
              onChange={e => handleImage(e)} // multiple
              multiple
            />
          </S.DropBoxItem>
        </S.DropBoxList>
      </S.DropBox>
      <UploadingBox Files={model} />
    </>
  );
}
