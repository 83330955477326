import React from 'react';
import ModalLayout from 'widgets/modal/ModalLayout';
import CanvasLayout from 'widgets/3dModel/CanvasLayout';
import TeethModel from 'widgets/3dModel/TeethModel';
import { useAtomValue } from 'jotai';
import { modelAtom } from 'app/jotai/modelState';
const PreviewModal = ({
  setIsModalOpen,
}: {
  setIsModalOpen: (state: boolean) => void;
}) => {
  const modelState = useAtomValue(modelAtom);

  return (
    <ModalLayout setIsModalOpen={setIsModalOpen} title="Preview">
      <CanvasLayout>
        {modelState?.model.map(str => {
          if (str === 'crown') {
            return <></>;
          }
          if (str === 'veneer') {
            return <></>;
          }
          if (modelState?.type === 'denture') {
            if (str === 'upper') {
              return (
                <TeethModel
                  path={`${modelState.type}/${str}`}
                  position={[0, -100, 0]}
                />
              );
            } else {
              return <TeethModel path={`${modelState.type}/${str}`} />;
            }
          } else {
            return <TeethModel path={`${modelState.type}/${str}`} />;
          }
        })}
      </CanvasLayout>
    </ModalLayout>
  );
};

export default PreviewModal;
