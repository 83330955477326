import React from 'react';
import { userInputData } from './consts/userInputData';
import useInputs from './model/useInputs';
import * as S from 'shared/styles/index.style';
import {
  UserIconComponent,
  PasswordIconComponent,
  PasswordCheckIconComponent,
  MailIconComponent,
} from 'assets/icon';
import FormInput from '../../widgets/Form/FormInput';
import SubmitButton from './UI/SubmitButton';
import useFormHandler from './model/useFormHandler';
import FormHeader from './UI/FormHeader';
const SignupPage = () => {
  const [userInput, inputHandler] = useInputs(userInputData);
  const { formHandler, inputError } = useFormHandler(userInput);

  return (
    <S.FormBox onSubmit={formHandler}>
      <FormHeader />
      <FormInput
        icon={<MailIconComponent />}
        placeholder="Email"
        name="email"
        maxLength={30}
        inputHandler={inputHandler}
        buttonName="Verify"
      />
      <S.FormErrorMessage>{inputError.email}</S.FormErrorMessage>
      <FormInput
        icon={<UserIconComponent />}
        placeholder="Name"
        name="username"
        maxLength={10}
        inputHandler={inputHandler}
      />
      <S.FormErrorMessage>{inputError.username}</S.FormErrorMessage>
      <FormInput
        icon={<PasswordIconComponent />}
        placeholder="Password"
        name="password"
        type="password"
        maxLength={20}
        inputHandler={inputHandler}
      />
      <S.FormErrorMessage>{inputError.password}</S.FormErrorMessage>
      <FormInput
        icon={<PasswordCheckIconComponent />}
        placeholder="PasswordCheck"
        type="password"
        name="passwordCheck"
        maxLength={20}
        inputHandler={inputHandler}
      />
      <S.FormErrorMessage>{inputError.password}</S.FormErrorMessage>

      <SubmitButton />
    </S.FormBox>
  );
};

export default SignupPage;
