import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { repositoryType } from 'repoType';
import RepositoryList from 'widgets/repositoryList/RepositoryList';
import { fetchGetRepo } from '../api/getRepo';
import { useAtomValue } from 'jotai';
import { repoAtom } from 'app/jotai/repoState';

const RepoList = ({
  checkedControl,
}: {
  checkedControl: {
    isAllRepoChecked: boolean;
    setIsAllRepoChecked: (state: boolean) => void;
  };
}) => {
  const { isAllRepoChecked, setIsAllRepoChecked } = checkedControl;
  const { isLoading, isError, data, error } = useQuery({
    queryKey: ['repo'],
    queryFn: fetchGetRepo,
  });
  const repoList = useAtomValue(repoAtom);
  const lists = data as { data: { content: [] } };
  const listsData = lists?.data?.content as Array<repositoryType>;

  // if (isLoading) {
  //   return <div>isLoading</div>;
  // }
  if (isError) {
    return <div>Error!</div>;
  } else {
    return (
      <>
        {repoList?.map(obj => {
          return (
            <RepositoryList
              // key={obj?.id}
              repo={obj}
              checkedControl={{
                isAllRepoChecked,
                setIsAllRepoChecked,
              }}
            />
          );
        })}
        {/* {listsData?.map(obj => {
          return (
            <RepositoryList
              key={obj?.id}
              repo={obj}
              checkedControl={{
                isAllRepoChecked,
                setIsAllRepoChecked,
              }}
            />
          );
        })} */}
      </>
    );
  }
};

export default RepoList;
