import React from 'react';
import { modelAtom } from 'app/jotai/modelState';
import { useAtomValue } from 'jotai';
import CanvasLayout from 'widgets/3dModel/CanvasLayout';
import TeethModel from 'widgets/3dModel/TeethModel';
type IsVisibleStructureProps = {
  [key: string]: boolean;
};
const Models = ({
  isVisibleStructure,
  isCreated,
}: {
  isVisibleStructure: IsVisibleStructureProps;
  isCreated: boolean;
}) => {
  const modelState = useAtomValue(modelAtom);

  return (
    <CanvasLayout>
      {Object.entries(isVisibleStructure).map(arr => {
        const model = arr[0];
        const isVisible = arr[1];
        if (isVisible) {
          if (model === 'crown') {
            // 보철물인 경우
            if (isCreated) {
              return (
                <TeethModel
                  path={`${modelState.type}/${model}`}
                  type="none"
                  color="gray"
                  position={[0, 0, 0]}
                />
              );
            }
            return <></>;
          } else {
            // 치아모델인 경우
            return <TeethModel path={`${modelState.type}/${model}`} />;
          }
        } else {
          return <></>;
        }
      })}
    </CanvasLayout>
  );
};

export default Models;
