import { colors } from 'shared/consts/color';
import { ButtonPropsType } from 'ButtonType';
import styled, { css } from 'styled-components';

// eslint-disable-next-line no-restricted-globals
const sizeStyles = css<ButtonPropsType>`
  ${props =>
    props.size === 'large' &&
    css`
      height: 2.5rem;
      font-size: 1rem;
    `}

  ${props =>
    props.size === 'small' &&
    css`
      height: 2rem;
      font-size: 0.75rem;
    `}
`;

export const BasicButton = styled.button<ButtonPropsType>`
  /* 공통스타일 */
  border: none;
  border-radius: 8px;
  padding: 0 1em;
  background-color: ${colors.Primary.Primary};

  color: ${props => (props.$textColor ? props.$textColor : colors.White)};
  font-size: 0.75em;
  cursor: pointer;
  border: ${props => (props.$borderColor ? '1px solid' : 'none')};
  border-color: ${props => (props.$borderColor ? props.$borderColor : 'none')};
  background-color: ${props =>
    props.buttonColor ? props.buttonColor : colors.Primary.Primary};
  /* 크기 */
  ${sizeStyles}
`;

export const AbsoluteBasicButton = styled(BasicButton)`
  position: absolute;
  z-index: 999;
  margin: 0.875em;
  /* bottom: 1.188em;
  right: 1.188em; */
`;
