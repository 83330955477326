import React from 'react';
import ReactDOM from 'react-dom/client';
import 'app/reset.css';
import App from './App';
import { createStore } from 'jotai';
import { Provider } from 'jotai';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

// jotai
const jotaiStore = createStore();

// tanstack query
const queryClient = new QueryClient();

root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={jotaiStore}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </QueryClientProvider>,
);
