import React from 'react';
import ModalLayout from 'widgets/modal/ModalLayout';
import * as S from 'shared/styles/index.style';
import ScanData from './ScanData';
import VerificationData from './VerificationData';
import { Graph } from 'assets/logo';

const Modal = ({
  isModalOpen,
  setIsModalOpen,
}: {
  isModalOpen: boolean;
  setIsModalOpen: (state: boolean) => void;
}) => {
  if (isModalOpen) {
    return (
      <ModalLayout setIsModalOpen={setIsModalOpen} title="Verification">
        <S.VerificationModalGrid>
          <ScanData />
          <VerificationData />
          <img src={Graph} />
        </S.VerificationModalGrid>
      </ModalLayout>
    );
  }
  return <></>;
};

export default Modal;
