import axios from 'axios';
import { serverURL } from '../../../shared/endpoint/serverUrl';
import { accessToken } from 'shared/token/jwtToken';

export const deleteRepo = async (idArray: Array<number>) => {
  try {
    const repos = await axios.delete(`${serverURL}/repository?ids=1`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: '*/*',
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    });
  } catch (error) {
    console.log(error);
  }
};
