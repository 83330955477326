import React from 'react';
import { colors } from 'shared/consts/color';
import { GoogleLogo } from 'assets/logo';
import * as S from 'shared/styles/index.style';
const SubmitButton = () => {
  return (
    <>
      <S.FormButton
        type="submit"
        $textColor={colors.White}
        bgColor={colors.Primary.Primary}
        border={false}
      >
        Login
      </S.FormButton>
      <S.LineWithText>
        <S.FormLine />
        <S.FormLineText>OR</S.FormLineText>
        <S.FormLine />
      </S.LineWithText>
      <S.FormButton
        type="submit"
        $textColor={colors.Black}
        bgColor={colors.White}
        border={true}
      >
        <img src={GoogleLogo} />
        <div>Google</div>
      </S.FormButton>
    </>
  );
};

export default SubmitButton;
