import { userAtom } from 'app/jotai/userState';
import Router from 'app/Router';
import { getAccessTokenWithRefreshToken } from 'features/auth/AuthAPI';
import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { accessToken, decodeToken } from 'shared/token/jwtToken';

function App() {
  const [_, setUserInfo] = useAtom(userAtom);
  useEffect(() => {
    if (accessToken) {
      getAccessTokenWithRefreshToken(accessToken)?.then(token => {
        if (token) {
          // accessToken에 담긴 유저정보를 해독하고
          decodeToken(token);
          const { sub, username } = decodeToken(token) ?? {};

          // 유저정보는 jotai 전역에 담아야함
          setUserInfo({
            email: sub,
            name: username,
          });
        }
      });
    }
  }, []);

  return <Router />;
}

export default App;
