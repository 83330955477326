import { colors } from 'shared/consts/color';
import { styled } from 'styled-components';
export const ScanData = styled.div<{ height?: string }>`
  border: 1px solid ${colors.WarmGray[300]};
  border-radius: 10px;
  margin: 1em;
  height: ${props => (props.height ? props.height : '12em')};
`;

export const GraphImg = styled.img``;

export const AccuracyText = styled.div`
  color: #000000;
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
  margin-top: 1em;
`;
