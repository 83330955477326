import React from 'react';
import * as S from 'shared/styles/index.style';
import { LayoutPropsType } from 'LayoutType';

const SmallModalBox = ({ title, children }: LayoutPropsType) => {
  return (
    <S.SmallModalBox>
      <S.SmallModalBoxTitle>{title}</S.SmallModalBoxTitle>
      <S.SmallModalContent>{children}</S.SmallModalContent>
    </S.SmallModalBox>
  );
};

export default SmallModalBox;
