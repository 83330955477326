import { encryptPublicKey } from 'features/RSA/rsaFunc';
import { UserInputType } from 'UserType';

export const encryptUserData = async (
  userInput: UserInputType,
  password: string,
) => {
  // api 실행
  const encodedPassword = await encryptPublicKey(password);
  const selectedInfo = {
    ...userInput,
    password: encodedPassword,
  } as UserInputType;

  delete selectedInfo.passwordCheck; // passwordCheck는 api에 넘기면 안됨

  return selectedInfo;
};
