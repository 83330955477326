import React, { useState } from 'react';
import Option from 'widgets/optionList/Option';
import OptionLayout from 'widgets/optionList/OptionLayout';
import { geOptionIcon } from 'assets/icon';
import Toggle from 'widgets/toggle/Toggle';

const GenerationOption = ({
  isActive,
  setIsActive,
}: {
  isActive: boolean;
  setIsActive: (state: boolean) => void;
}) => {
  return (
    <OptionLayout>
      <Option icon={geOptionIcon} name="Option" />
      <Toggle isActive={isActive} setIsActive={setIsActive} />
    </OptionLayout>
  );
};

export default GenerationOption;
