import React from 'react';
import BasicCheckBox from '../button/BasicCheckBox';
import * as S from 'shared/styles/index.style';

/** images */
import {
  CheckTure,
  CheckFalse,
  PaymentTrue,
  PaymentFalse,
  Option,
} from 'assets/icon';
import { repoInfoPropsType } from 'propsType';
import { prosthesis } from './consts/icon';
import { changeGmtToKoreanDate } from 'shared/helpers/date';
import { useAtom } from 'jotai';
import { checkedReposAtom } from 'app/jotai/checkedRepoState';
import { useNavigate } from 'react-router-dom';

const RepositoryList = ({ repo, checkedControl }: repoInfoPropsType) => {
  const { createdAt, id, isPaid, isVerified, title, type } = repo;
  const { isAllRepoChecked, setIsAllRepoChecked } = checkedControl;
  const [checkedRepo, setCheckedRepo] = useAtom(checkedReposAtom);
  const navigate = useNavigate();
  const isExist = checkedRepo.filter(repoId => id === repoId).length > 0;
  const checkTheList = () => {
    if (isExist) {
      if (isAllRepoChecked) {
        setIsAllRepoChecked(false);
      }
      setCheckedRepo(checkedRepo.filter(repoId => repoId !== id));
    } else {
      setCheckedRepo([...checkedRepo, id]);
    }
  };

  const clickRepo = (e: React.MouseEvent<HTMLElement>) => {
    navigate(`/repository/${id}`);
  };

  return (
    <S.RepositoryList $checkedrepo={isExist}>
      <BasicCheckBox checkFunc={checkTheList} isChecked={isExist} />
      <S.NameRowItem onClick={clickRepo}>
        <img src={prosthesis[type]} />
        <S.NameBox>
          <p>{title}</p>
          <span>{type}</span>
        </S.NameBox>
      </S.NameRowItem>
      <S.DateRowItem>
        <span>{changeGmtToKoreanDate(createdAt)}</span>
      </S.DateRowItem>
      <S.VerificationRowItem>
        {isVerified ? <img src={CheckTure} /> : <img src={CheckFalse} />}
      </S.VerificationRowItem>
      <S.PaymentRowItem>
        {isPaid ? <img src={PaymentTrue} /> : <img src={PaymentFalse} />}
      </S.PaymentRowItem>
      <S.OptionBtnRowItem>
        <img src={Option} />
      </S.OptionBtnRowItem>
    </S.RepositoryList>
  );
};

export default RepositoryList;
