import React from 'react';
import * as S from 'shared/styles/index.style';
import CanvasLayout from 'widgets/3dModel/CanvasLayout';
import ToolBoxLayout from 'widgets/toolBox/ToolBoxLayout';
import ExtractionButtons from './UI/ExtractionButtons';
import ExtractionOption from './UI/ExtractionOption';
import TeethModel from 'widgets/3dModel/TeethModel';
import { useAtomValue } from 'jotai';
import { modelAtom } from 'app/jotai/modelState';
import BasicRoundButton from 'widgets/button/BasicRoundButton';
import { colors } from 'shared/consts/color';
import { useNavigate } from 'react-router-dom';

const STLExtraction = () => {
  const modelState = useAtomValue(modelAtom);
  const navigate = useNavigate();
  const path = `${modelState.type}/object`;
  return (
    <S.CreationSection>
      <CanvasLayout>
        {modelState.type === 'denture' ? (
          <>
            <TeethModel
              path={`${modelState.type}/upperteeth`}
              position={[0, -42, 60]}
              rotation={[0, 9.4, 9.5]}
            />
            <TeethModel
              path={`${modelState.type}/lowerteeth`}
              position={[0, 0, -10]}
            />
          </>
        ) : (
          <TeethModel path={path} />
        )}
      </CanvasLayout>
      <ToolBoxLayout>
        {modelState.type === 'denture' ? (
          <>
            <ExtractionButtons path={`${modelState.type}/upperteeth`} />
            <ExtractionButtons path={`${modelState.type}/lowerteeth`} />
            <a>
              <BasicRoundButton
                name="Cancel"
                buttonColor="transparent"
                textColor={colors.Primary.Primary}
                borderColor={colors.Primary.Primary}
                action={() => navigate('/creation')}
              />
            </a>
          </>
        ) : (
          <>
            <ExtractionButtons path={path} />
            <a>
              <BasicRoundButton
                name="Cancel"
                buttonColor="transparent"
                textColor={colors.Primary.Primary}
                borderColor={colors.Primary.Primary}
                action={() => navigate('/creation')}
              />
            </a>
          </>
        )}
        {/* <ExtractionButtons path={path} /> */}
        <ExtractionOption />
      </ToolBoxLayout>
    </S.CreationSection>
  );
};

export default STLExtraction;
