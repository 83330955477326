import { Route, Routes, useLocation } from 'react-router-dom';
import CreationPage from '../pages/generation/GenerationPage';
import ExtractionPage from '../pages/extraction/ExtractionPage';
import RepositoriesPage from '../pages/repositoryList/RepositoriesPage';
import NewRepositoryPage from '../pages/newRepository/NewRepositoryPage';
import VerificationPage from '../pages/verification/VerificationPage';
import SignupPage from '../pages/signup/SignupPage';
import HomePage from '../pages/home/HomePage';
import RouterTransition from './RouterTransition';
import TestPage from 'pages/test/TestPage';
import DashboardLayout from 'app/UI/DashboardLayout';
import MainLayout from './UI/MainLayout';
import LoginPage from 'pages/login/LoginPage';
import FormLayout from './UI/FormLayout';
import ThreePageLayout from './UI/ThreePageLayout';
import Preview from 'pages/preview/Preview';

const Router = () => {
  const location = useLocation();

  return (
    <RouterTransition locationKey={location.key}>
      <Routes key={location.pathname} location={location}>
        <Route element={<FormLayout />}>
          <Route element={<SignupPage />} path="/signup" />
          <Route element={<LoginPage />} path="/login" />
        </Route>
        <Route element={<MainLayout />}>
          <Route element={<HomePage />} path="/" />
          <Route element={<TestPage />} path="/test" />
        </Route>
        <Route element={<ThreePageLayout />}>
          <Route element={<CreationPage />} path="/creation" />
          <Route element={<ExtractionPage />} path="/extraction" />
          <Route element={<CreationPage />} path="/repository/:id" />
        </Route>
        <Route element={<DashboardLayout />}>
          <Route element={<RepositoriesPage />} path="/repositories" />
          <Route element={<NewRepositoryPage />} path="/new-repository" />
          <Route element={<Preview />} path="/preview" />
          <Route element={<VerificationPage />} path="/verification" />
        </Route>
      </Routes>
    </RouterTransition>
  );
};

export default Router;
