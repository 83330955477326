import { colors } from 'shared/consts/color';
import { styled } from 'styled-components';
export const NavHeaderBox = styled.div`
  position: relative;
  display: flex;
  width: 100vw;
  padding: 1.5em 7.5em 1.5em 7.5em;
  height: 6em;
  border-bottom: 1px solid ${colors.WarmGray[25]};
  background-color: ${colors.White};
  justify-content: space-around;
`;

export const HeaderLogo = styled.div`
  width: 100%;
  img {
    display: block;
    width: 6.25em;
  }
`;

export const NavMenu = styled.div`
  position: absolute;
  display: flex;
  top: 50%;
  color: ${colors.Primary.Primary};
  font-weight: bold;

  a {
    margin-right: 1.75em;
    margin-top: -0.5em;
    cursor: pointer;
  }
`;

export const NavButton = styled.div`
  margin-right: 1.75em;
`;
