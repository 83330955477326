import React from 'react';
import { useNavigate } from 'react-router-dom';
import { colors } from 'shared/consts/color';
import BasicRoundButton from 'widgets/button/BasicRoundButton';

const ExtractionButtons = ({ path }: { path: string }) => {
  const navigate = useNavigate();

  return (
    <>
      <a href={`${process.env.PUBLIC_URL}/stl_model/${path}.stl`} download>
        <BasicRoundButton name="Download" />
      </a>
    </>
  );
};

export default ExtractionButtons;
