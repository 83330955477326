import { colors } from 'shared/consts/color';
import { styled } from 'styled-components';
export const CreationSection = styled.section`
  /* height: calc(100vh - 8em); */
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
  background-color: ${colors.WarmGray[200]};
`;

export const CreationSettingContainer = styled.div`
  position: absolute;
  background-color: ${colors.White};
  border-radius: 20px;
  bottom: 3em;
  right: 3em;
`;

export const OptionMenuContainer = styled.ul`
  margin-bottom: 2em;
  display: flex;
  justify-content: space-around;
  li {
    width: fit-content;
    display: flex;
    align-items: center;
    /* padding-top: 1em; */
  }

  img {
    display: block;
    height: 1.125em;
    padding-right: 0.5em;
  }

  a {
    display: block;
    font-size: 0.75em;
    color: #98a2b3;
    font-weight: 600;
  }
`;
