import React from 'react';
import * as S from 'shared/styles/index.style';
const FieldName = ({ name }: { name: string }) => {
  return (
    <S.TitleItem>
      <p>{name}</p>
    </S.TitleItem>
  );
};

export default FieldName;
