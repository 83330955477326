import axios from 'axios';
import { serverURL } from 'shared/endpoint/serverUrl';

export const fetchLogin = async (userInput: any) => {
  try {
    const fetchData = await axios.post(`${serverURL}/user/login`, userInput, {
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    });
    const {
      data: { jwtTokenDto: accessToken },
    } = fetchData;
    return accessToken;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};
