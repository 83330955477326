import React from 'react';
import { useNavigate } from 'react-router-dom';

const OptionMenuList = ({
  icon,
  title,
  path,
  hightlight,
}: {
  icon: string;
  title: string;
  path: string;
  hightlight?: boolean;
}) => {
  const navigate = useNavigate();
  const navigationHandler = (
    event: React.MouseEvent<HTMLAnchorElement>,
    path: string,
  ): void => {
    event.preventDefault();
    navigate(path);
  };
  return (
    <li className={hightlight ? 'highlight' : ''}>
      <img src={icon} />
      <a
        href=""
        onClick={event => {
          navigationHandler(event, path);
        }}
      >
        {title}
      </a>
    </li>
  );
};

export default OptionMenuList;
