import { decodeToken } from 'shared/token/jwtToken';
import { encryptUserData } from 'pages/signup/model/encryptData';
import React from 'react';
import { fetchLogin } from '../api/fetchLogin';
import { useAtom } from 'jotai';
import { tokenAtom } from 'app/jotai/tokenState';
import { userAtom } from 'app/jotai/userState';
import { useNavigate } from 'react-router-dom';

const useFormHandler = (userInput: any) => {
  // login버튼을 누르면 발생하는 일을 총괄
  const [token, setToken] = useAtom(tokenAtom);
  const [_, setUserInfo] = useAtom(userAtom);
  const navigate = useNavigate();

  const formHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // 비밀번호 암호화해서 백엔드로 보냄
    const { password } = userInput;
    const userData = await encryptUserData(userInput, password);

    // 서버에서 accessToken을 받아옴
    const fetchingData = await fetchLogin(userData);
    const { accessToken } = fetchingData ?? {}; // null or undefined => {}

    if (accessToken) {
      // accessToken을 local storage에 담아야함
      window.localStorage.setItem('accessToken', accessToken);

      // accessToken에 담긴 유저정보를 해독하고
      const decodedInfo = decodeToken(accessToken);

      // 유저정보는 jotai 전역에 담아야함
      const { sub, username } = decodedInfo;

      setUserInfo({
        email: sub,
        name: username,
      });

      // 로그인 성공 시 home으로
      navigate('/');
      return { accessToken, decodedInfo };
    }
  };
  return { formHandler };
};

export default useFormHandler;
