import React from 'react';
import * as S from 'shared/styles/index.style';
import { LayoutPropsType } from 'LayoutType';

function ModelBoxLayout({ children, title }: LayoutPropsType) {
  return (
    <S.ModelBox>
      <S.ModelBoxTitle>{title}</S.ModelBoxTitle>
      {children}
    </S.ModelBox>
  );
}

export default ModelBoxLayout;
