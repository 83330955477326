import { colors } from 'shared/consts/color';
import styled from 'styled-components';

export const ToggleBox = styled.div<{ isActive: boolean }>`
  background-color: ${props =>
    props.isActive ? colors.Primary[600] : colors.WarmGray[300]};
  border-radius: 50px; // 동그란 모양을 더 자연스럽게
  width: 2em; // 토글의 전체 길이를 조정
  height: 1.125em; // 토글의 높이를 설정
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
`;

export const ToggleCircle = styled.div<{ isActive: boolean }>`
  background-color: ${props =>
    props.isActive ? colors.Primary.Primary : colors.WarmGray[500]};
  border-radius: 50%;
  width: 0.875em; // 동그라미의 크기
  height: 0.875em; // 동그라미의 크기
  transition: transform 0.3s ease-in-out;
  transform: translateX(
    ${props => (props.isActive ? '1em' : '0.15em')}
  ); // isActive 상태에 따라 위치 변경
  position: absolute; // 토글 내에서의 절대 위치
`;

export const ToggleOptionBox = styled.div`
  position: absolute;
  width: 13.375em;
  height: 15em;
  bottom: 20em;
  right: 3em;
  opacity: 60%;
  border-radius: 20px;
  background-color: ${colors.White};
`;

export const ToggleOptionListContainer = styled.ul`
  padding: 1.75em;

  li {
    margin-bottom: 1.5em;
    display: flex;
    justify-content: space-between;
  }
`;
