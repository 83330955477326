import React from 'react';
import CanvasLayout from 'widgets/3dModel/CanvasLayout';
import * as S from 'shared/styles/index.style';
import TeethModel from 'widgets/3dModel/TeethModel';
import { useAtomValue } from 'jotai';
import { modelAtom } from 'app/jotai/modelState';

const ScanData = () => {
  const modelState = useAtomValue(modelAtom);

  return (
    <div>
      <S.ScanData>
        <S.ModelBoxTitle>Outer Scan Data</S.ModelBoxTitle>
        <CanvasLayout>
          <TeethModel path={`${modelState.type}/object - OUT`} />
        </CanvasLayout>
      </S.ScanData>
      <S.ScanData>
        <S.ModelBoxTitle>Inner Scan Data</S.ModelBoxTitle>
        <CanvasLayout>
          <TeethModel path={`${modelState.type}/object - IN`} />
        </CanvasLayout>
      </S.ScanData>
    </div>
  );
};

export default ScanData;
