import { atom } from 'jotai';
import { repoInfoPropsType, repoType } from 'propsType';

export const repoAtom = atom<repoType[]>([
  // {
  //   createdAt: '2024-01-30T04:50:54.85547',
  //   id: 1232142,
  //   isPaid: true,
  //   isVerified: true,
  //   title: "Sam's Implant",
  //   type: 'Implant',
  // },
  // {
  //   createdAt: '2024-01-15T04:50:54.85547',
  //   id: 242132190321,
  //   isPaid: true,
  //   isVerified: false,
  //   title: "John's Denture",
  //   type: 'Denture',
  // },
]);
