import React, { useState } from 'react';
import * as S from 'shared/styles/index.style';
import ToolBoxLayout from 'widgets/toolBox/ToolBoxLayout';
import GenerationOption from './UI/GenerationOption';
import GenerationButtons from './UI/GenerationButtons';
import ToggleOptionBox from './UI/ToggleOptionBox';
import Models from './UI/Models';
import LoadingIndicator from 'widgets/loadingIndicator/LoadingIndicator';
import { useAtom, useAtomValue } from 'jotai';
import { modelAtom } from 'app/jotai/modelState';
import VeneerModel from './UI/VeneerModel';
import DentureModel from './UI/DentureModel';
import { objectAtom } from 'app/jotai/objectState';
import Loading from './UI/Loading';

const GenerationPage = () => {
  const [objectState, _] = useAtom(objectAtom);
  const isCreated = objectState.isCreated;
  const [isActive, setIsActive] = useState(false);
  const modelState = useAtomValue(modelAtom);
  const object = {} as any;
  modelState.model.forEach((value: string, idx: number) => {
    object[value] = true;
  });

  const [isVisibleStructure, setIsVisibleStructure] = useState(object);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <S.CreationSection>
      {isLoading && <Loading />}
      {modelState.type === 'laminate' || modelState.type === 'denture' ? (
        modelState.type === 'laminate' ? (
          <VeneerModel
            isVisibleStructure={isVisibleStructure}
            isCreated={isCreated}
          />
        ) : (
          <DentureModel
            isVisibleStructure={isVisibleStructure}
            isCreated={isCreated}
          />
        )
      ) : (
        <Models isVisibleStructure={isVisibleStructure} isCreated={isCreated} />
      )}

      {isActive && (
        <ToggleOptionBox
          isVisibleStructure={isVisibleStructure}
          setIsVisibleStructure={setIsVisibleStructure}
        />
      )}
      <ToolBoxLayout>
        <GenerationButtons setIsLoading={setIsLoading} />
        <GenerationOption isActive={isActive} setIsActive={setIsActive} />
      </ToolBoxLayout>
    </S.CreationSection>
  );
};

export default GenerationPage;
