import React from 'react';

import * as S from 'shared/styles/index.style';

function IconButton(buttonInfo: { icon: string; action: (state: any) => any }) {
  return (
    <S.IconButton
      icon={buttonInfo.icon}
      onClick={buttonInfo.action}
    ></S.IconButton>
  );
}

export default IconButton;
