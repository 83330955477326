import React from 'react';
import { useNavigate } from 'react-router-dom';
import BasicRoundButton from 'widgets/button/BasicRoundButton';
import { useAtom } from 'jotai';
import { objectAtom } from 'app/jotai/objectState';

const GenerationButtons = ({
  setIsLoading,
}: {
  setIsLoading: (state: boolean) => void;
}) => {
  const navigate = useNavigate();
  const [objectState, setObjectState] = useAtom(objectAtom);
  const isCreated = objectState.isCreated;

  const createHandler = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setObjectState({ isCreated: true, type: '' });
    }, 10000);
  };

  return (
    <>
      {isCreated ? (
        <>
          <BasicRoundButton
            name="Extraction"
            action={() => navigate('/extraction')}
          />
          <BasicRoundButton
            name="Verify"
            action={() => navigate('/verification')}
          />
        </>
      ) : (
        <BasicRoundButton name="Generate" action={createHandler} />
      )}
    </>
  );
};

export default GenerationButtons;
