import base64 from 'base-64';

export const accessToken = window.localStorage.getItem('accessToken');
export const refreshToken = document.cookie.split('=')[1];

export const decodeToken = (token: string) => {
  const payload = token?.substring(
    token.indexOf('.') + 1,
    token.lastIndexOf('.'),
  );

  const decoded = base64?.decode(payload);
  const decodedInfo = JSON?.parse(decoded); // json을 일반객체로

  return decodedInfo;
};
