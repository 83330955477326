import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as S from 'shared/styles/index.style';

const FormHeader = () => {
  const navigate = useNavigate();

  return (
    <S.FormHeader>
      <S.FormTitle>Create an account </S.FormTitle>
      <S.FormSubText>
        Already have an account?
        <span onClick={() => navigate('/login')}> Sign in</span>
      </S.FormSubText>
    </S.FormHeader>
  );
};

export default FormHeader;
