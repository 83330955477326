import React from 'react';
import { RealTeethLogo } from 'assets/logo';
import * as S from 'shared/styles/index.style';
import { useNavigate } from 'react-router-dom';

const FormLogo = () => {
  const navigate = useNavigate();
  return (
    <S.FormLogo>
      <img src={RealTeethLogo} onClick={() => navigate('/')} />
    </S.FormLogo>
  );
};

export default FormLogo;
