import axios from 'axios';
import { ResponseErrorType } from 'ErrorType';
import { UserInputType } from 'UserType';
import { serverURL } from 'shared/endpoint/serverUrl';

export const fetchSignup = async (userInput: UserInputType | any) => {
  try {
    const fetchData = await axios.post(`${serverURL}/user/signup`, userInput, {
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    });
    const {
      status,
      data: { accessToken, refreshToken },
    } = fetchData;

    return { accessToken, refreshToken, status };
  } catch (error) {
    console.log(error);
    const {
      response: {
        status,
        data: { message },
      },
    } = error as ResponseErrorType;
    return { status, message };
  }
};

export const fetchUserInfo = async (accessToken: string | null) => {
  const fetchData = await axios.get(`${serverURL}/auth/user`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });

  return fetchData;
};

export const fetchNewToken = async (
  accessToken: string | null,
  refreshToken: string | null,
) => {
  const fetchData = await axios.post(`${serverURL}/auth/refresh`, {
    accessToken,
    refreshToken,
  });
  const {
    data: { newAccessToken, newRefreshToken },
  } = fetchData;

  return { newAccessToken, newRefreshToken };
};

export const getAccessTokenWithRefreshToken = async (accessToken: string) => {
  try {
    const fetchData = await axios.get(`${serverURL}/user/refresh`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      withCredentials: true,
    });
    const { data } = fetchData;
    window.localStorage.setItem('accessToken', data?.accessToken);
    return data?.accessToken;
  } catch (error) {
    console.log(error);
  }
};
