import {
  validateEmail,
  validateName,
  validatePassword,
} from 'shared/helpers/validation';

export const getErrorMessage = (userInput: any, inputError: any) => {
  const { email, username, password, passwordCheck } = userInput;
  const emailValidation = validateEmail(email);
  const nameValidation = validateName(username);
  const passwordValidation = validatePassword(password, passwordCheck);
  if (!emailValidation.isCorrect)
    return { ...inputError, email: emailValidation.errorMessage };

  if (!nameValidation.isCorrect)
    return { ...inputError, username: nameValidation.errorMessage };

  if (!passwordValidation.isCorrect)
    return {
      ...inputError,
      password: passwordValidation.errorMessage,
    };
};
