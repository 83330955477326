import React from 'react';
import * as S from 'shared/styles/index.style';
import { LayoutPropsType } from 'LayoutType';

const VerificationDropBoxContainer = ({ title, children }: LayoutPropsType) => {
  return (
    <S.SmallDropboxContainer>
      <S.DropboxTitle>{title}</S.DropboxTitle>
      {children}
    </S.SmallDropboxContainer>
  );
};

export default VerificationDropBoxContainer;
