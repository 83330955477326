import { fetchPublicKey } from 'features/RSA/rsaAPI';
import { encryptPublicKey } from 'features/RSA/rsaFunc';
import React, { useState } from 'react';

const TestPage = () => {
  const [publicKey, setPublicKey] = useState('');
  const [password, setPassword] = useState('');
  const [encrytedPassword, setEncytedPassword] = useState<string | boolean>('');
  const inputPublicKey = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPublicKey(e.target.value);
  };
  const inputPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };
  const encrypt = async () => {
    setEncytedPassword(await encryptPublicKey(password, publicKey));
  };

  return (
    <>
      <input placeholder="publicKey" onChange={inputPublicKey} />
      <input placeholder="password" onChange={inputPassword} />
      <div>{}</div>
      <button onClick={encrypt}>encrypt</button>
      <br />
      <br />
      <br />
      <div>encrypted password</div>
      <div>{encrytedPassword}</div>
    </>
  );
};

export default TestPage;
