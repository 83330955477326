import React, { useState } from 'react';

const useInputs = (initialData: any) => {
  const [userInput, setUserInput] = useState(initialData);

  const inputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserInput({ ...userInput, [name]: value });
  };

  return [userInput, inputHandler];
};

export default useInputs;
