import styled from 'styled-components';

const margin = 18;

export const AppBox = styled.div`
  display: flex;
`;

export const TemplateBox = styled.div<{
  templateboxbgcolor?: string;
  templateMargin?: string;
}>`
  width: ${props =>
    props.templateMargin
      ? `calc(100% - ${props.templateMargin})`
      : `${margin}%`};
  margin-left: ${props =>
    props.templateMargin ? props.templateMargin : `${margin}%`};
  height: 100%;
  padding-right: 3.5rem;
  padding-top: 3.25em;
  background-color: ${props => props.templateboxbgcolor};
  position: relative;

  /* width: ${props =>
    props.templateMargin
      ? `calc(100% - ${margin}% - ${props.templateMargin})`
      : `${margin}%`};
  margin-left: ${props =>
    props.templateMargin
      ? `calc(${margin}% + ${props.templateMargin})`
      : `${margin}%`}; */
`;
