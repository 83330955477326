import React from 'react';
import { modelAtom } from 'app/jotai/modelState';
import { useAtomValue } from 'jotai';
import CanvasLayout from 'widgets/3dModel/CanvasLayout';
import TeethModel from 'widgets/3dModel/TeethModel';
type IsVisibleStructureProps = {
  [key: string]: boolean;
};
const DentureModel = ({
  isVisibleStructure,
  isCreated,
}: {
  isVisibleStructure: IsVisibleStructureProps;
  isCreated: boolean;
}) => {
  const modelState = useAtomValue(modelAtom);
  return (
    <CanvasLayout>
      {Object.entries(isVisibleStructure).map(arr => {
        const model = arr[0];
        const isVisible = arr[1];
        if (isVisible) {
          if (isCreated) {
            return (
              <>
                <TeethModel
                  path={`${modelState.type}/uppercomplete`}
                  position={[0, 0, 55]}
                />
                <TeethModel
                  path={`${modelState.type}/lowercomplete`}
                  position={[0, 0, 0]}
                />
              </>
            );
          } else {
            return (
              <>
                <TeethModel
                  path={`${modelState.type}/upper`}
                  position={[-5, 0, 70]}
                  rotation={[0, 9.4, 9.5]}
                />
                <TeethModel
                  path={`${modelState.type}/lower`}
                  rotation={[0, 0, 0]}
                />
              </>
            );
          }
        } else {
          return <></>;
        }
      })}
    </CanvasLayout>
  );
};

export default DentureModel;
