import React, { useEffect, useState } from 'react';
import * as S from 'shared/styles/index.style';
import { FileIcon, Cancel } from 'assets/icon';
import { useAtom } from 'jotai';
import { fileAtom } from 'app/jotai/fileState';
const UploadingList = ({
  fileName,
  fileSize,
  index,
}: {
  fileName: string;
  fileSize: number;
  index: number;
}) => {
  const [filesState, setFilesState] = useAtom(fileAtom);

  const [progressValue, setProgressValue] = useState(0);

  useEffect(() => {
    const id = setInterval(() => {
      if (progressValue < 100) {
        setProgressValue(progressValue + 1);
      } else {
        clearInterval(id);
        setFilesState(
          filesState.map(() => {
            return true;
          }),
        );
      }
    }, fileSize / 10);

    return () => clearInterval(id);
  }, [progressValue, setFilesState]);

  return (
    <S.UploadingList>
      <S.UploadingItem>
        <img className="uploadingFileIcon" src={FileIcon} />
      </S.UploadingItem>
      <S.UploadingItem>
        <S.ProgressList>
          <S.ProgressItem>
            <span className="fileName">{fileName}</span>
            <img src={Cancel} />
          </S.ProgressItem>
          {progressValue === 100 ? undefined : (
            <S.ProgressItem>
              <progress id="progress" value={progressValue} max="100" />
              <span className="percentage">{progressValue}%</span>
            </S.ProgressItem>
          )}
          <S.ProgressItem>
            <span className="fileSize">{fileSize}MB</span>
          </S.ProgressItem>
        </S.ProgressList>
      </S.UploadingItem>
    </S.UploadingList>
  );
};

export default UploadingList;
