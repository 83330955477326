import React from 'react';
import { EyeCancelIcon, EyeIcon } from 'assets/icon';
import OptionBoxLayout from 'widgets/option/OptionBoxLayout';
import OptionList from 'widgets/option/OptionList';
import { toggleStateByKey } from 'shared/helpers/toggle';

type IsVisibleStructureProps = {
  [key: string]: boolean;
};

const ToggleOptionBox = ({
  isVisibleStructure,
  setIsVisibleStructure,
}: {
  isVisibleStructure: IsVisibleStructureProps;
  setIsVisibleStructure: (state: IsVisibleStructureProps) => void;
}) => {
  const changeVisibleStatusHanlder = (key: string) => {
    setIsVisibleStructure(toggleStateByKey(isVisibleStructure, key));
  };

  return (
    <OptionBoxLayout>
      {Object.entries(isVisibleStructure).map(([key, value], index) => {
        return (
          <OptionList
            key={index}
            name={key}
            icon={value ? EyeIcon : EyeCancelIcon}
            action={() => changeVisibleStatusHanlder(key)}
          />
        );
      })}
    </OptionBoxLayout>
  );
};

export default ToggleOptionBox;
