import React from 'react';
import {
  Dots,
  Bounce,
  Digital,
  Sentry,
  Squares,
  Levels,
  Windmill,
} from 'react-activity';
import 'react-activity/dist/library.css';
import * as S from 'shared/styles/index.style';

const LoadingIndicator = ({ title }: { title: string }) => {
  return (
    <S.LoadingIndicatorContainer>
      <Windmill size={40} style={{ width: '100%' }} />
      <S.LoadingIndicatorMessage>{title}</S.LoadingIndicatorMessage>
    </S.LoadingIndicatorContainer>
  );
};

export default LoadingIndicator;
