import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as S from 'shared/styles/index.style';

const FormHeader = () => {
  const navigate = useNavigate();
  return (
    <S.FormHeader>
      <S.FormTitle>Welcome back</S.FormTitle>
      <S.FormSubText>
        Do you have no account?
        <span onClick={() => navigate('/signup')}> Sign up</span>
      </S.FormSubText>
    </S.FormHeader>
  );
};

export default FormHeader;
