import { fetchSignup } from 'features/auth/AuthAPI';
import { saveToken } from 'features/auth/AuthToken';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  validateEmail,
  validateName,
  validatePassword,
} from 'shared/helpers/validation';
import { userInputData } from '../consts/userInputData';
import { encryptUserData } from './encryptData';
import { getErrorMessage } from './getErrorMessage';

const useFormHandler = (userInput: any) => {
  const navigate = useNavigate();

  const [inputError, setInputError] = useState(userInputData);
  const formHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { email, username, password, passwordCheck } = userInput;
    const emailValidation = validateEmail(email);
    const nameValidation = validateName(username);
    const passwordValidation = validatePassword(password, passwordCheck);

    if (
      emailValidation.isCorrect &&
      nameValidation.isCorrect &&
      passwordValidation.isCorrect
    ) {
      setInputError(userInputData); // error message 초기화

      // api 실행
      const userData = await encryptUserData(userInput, password);

      try {
        const responseData = await fetchSignup(userData);
        const { accessToken, refreshToken, status, message } = responseData;

        if (status !== 200) {
          setInputError({ ...inputError, email: message });
        } else {
          saveToken(accessToken, refreshToken); // 성공시 token 저장
          navigate('/'); // 로그인 성공시 home으로
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      const errorMessage = getErrorMessage(userInput, inputError);
      setInputError(errorMessage);
    }
  };
  return { formHandler, inputError };
};

export default useFormHandler;
