import React, { useEffect, useState } from 'react';
import * as S from 'shared/styles/index.style';
import BasicRoundButton from 'widgets/button/BasicRoundButton';
import { useNavigate } from 'react-router-dom';
import { colors } from 'shared/consts/color';
import { useAtom, useAtomValue } from 'jotai';
import { modelAtom } from 'app/jotai/modelState';
import { progressAtom } from 'app/jotai/progressState';
import CompleteModel from 'pages/preview/UI/partialModel/CompleteModel';
import PartialModel from 'pages/preview/UI/partialModel/PartialModel';
import PreviewModal from 'pages/preview/UI/modal/PreviewModal';
import Title from 'widgets/title/PageTitle';
import SaveModal from './UI/modal/SaveModal';

const Preview = () => {
  const navigate = useNavigate();
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const modelState = useAtomValue(modelAtom);
  const [_, setProgressValue] = useAtom(progressAtom);

  useEffect(() => {
    setProgressValue(false);
  }, []);

  return (
    <S.NewRepositorySection>
      {isPreviewModalOpen ? (
        <PreviewModal setIsModalOpen={setIsPreviewModalOpen} />
      ) : undefined}
      {isSaveModalOpen ? (
        <SaveModal setIsModalOpen={setIsSaveModalOpen} />
      ) : undefined}

      <Title name="New Repository" />
      <S.UploadBox>
        <S.UploadList>
          <section>
            <p>Generate Data</p>
            <S.PreviewBox>
              <CompleteModel setIsModalOpen={setIsPreviewModalOpen} />
              {modelState?.model.map(str => {
                if (str === 'crown') {
                  return <></>;
                }
                if (str === 'veneer') {
                  return <></>;
                }
                if (str === 'abutment2') {
                  return <></>;
                } else {
                  return (
                    <PartialModel
                      title={str}
                      path={`${modelState.type}/${str}`}
                    />
                  );
                }
              })}
            </S.PreviewBox>
          </section>
        </S.UploadList>
        <S.ButtonsLayout>
          <BasicRoundButton
            name="Save"
            action={() => setIsSaveModalOpen(true)}
          />
          <BasicRoundButton
            name="Cancel"
            buttonColor="transparent"
            textColor={colors.Primary.Primary}
            borderColor={colors.Primary.Primary}
            action={() => navigate('/repositories')}
          />
        </S.ButtonsLayout>
      </S.UploadBox>
    </S.NewRepositorySection>
  );
};

export default Preview;
