import React from 'react';
import { modelAtom } from 'app/jotai/modelState';
import { useAtomValue } from 'jotai';
import * as S from 'shared/styles/index.style';
import CanvasLayout from 'widgets/3dModel/CanvasLayout';
import TeethModel from 'widgets/3dModel/TeethModel';

const VerificationData = () => {
  const modelState = useAtomValue(modelAtom);
  const path = `${modelState.type}/object`;
  return (
    <S.ScanData height="22em">
      <CanvasLayout>
        {modelState.type === 'denture' ? (
          <>
            <TeethModel
              type="verified"
              path={`${modelState.type}/upperteeth`}
              position={[0, -42, 60]}
              rotation={[0, 9.4, 9.5]}
            />
            <TeethModel
              type="verified"
              path={`${modelState.type}/lowerteeth`}
              position={[0, 0, -10]}
            />
          </>
        ) : (
          <TeethModel type="verified" color="gray" path={path} />
        )}
      </CanvasLayout>
      <S.AccuracyText>Accuracy: 87㎛</S.AccuracyText>
    </S.ScanData>
  );
};

export default VerificationData;
