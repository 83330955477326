import { colors } from 'shared/consts/color';
import styled from 'styled-components';

export const BasicRoundButton = styled.button<{
  $textColor?: string;
  $buttonColor?: string;
  $borderColor?: string;
}>`
  /* 공통스타일 */
  width: 12.5em;
  height: 3.5em;
  margin: 1.75em 1.75em 1.5em 1.75em;
  border: ${props => (props.$borderColor ? '1px solid' : 'none')};
  border-color: ${props => (props.$borderColor ? props.$borderColor : 'none')};
  border-radius: 1000px;
  display: block;
  background-color: ${props =>
    props.$buttonColor ? props.$buttonColor : colors.Primary.Primary};
  color: ${props => (props.$textColor ? props.$textColor : colors.White)};
  /* font-size: 0.75em; */
  cursor: pointer;
`;
