import React, { useState } from 'react';
import BasicRoundButton from 'widgets/button/BasicRoundButton';
import LargeDropBox from 'widgets/dropBox/LargeDropBox';
import * as S from 'shared/styles/index.style';
import { useNavigate } from 'react-router-dom';
import { colors } from 'shared/consts/color';
import { ModelFileType } from 'FileType';
import { useAtom } from 'jotai';
import { fileAtom } from 'app/jotai/fileState';

const Upload = () => {
  const [contentModel, setContentModel] = useState<ModelFileType>([]);

  const navigate = useNavigate();
  const clickPreview = () => {
    if (contentModel) {
      navigate('/preview');
    }
  };
  const clickCancel = () => navigate('/repositories');

  const [filesState, _] = useAtom(fileAtom);
  const isLoaded = filesState.length
    ? filesState?.reduce((acc: any, cur: boolean) => acc && cur)
    : false;

  return (
    <section>
      <p>Upload</p>
      <LargeDropBox model={contentModel} setModel={setContentModel} />
      <S.ButtonsLayout>
        <BasicRoundButton
          name="Preview"
          buttonColor={isLoaded ? undefined : colors.WarmGray[400]}
          action={isLoaded ? clickPreview : () => undefined}
        />
        <BasicRoundButton
          name="Cancel"
          buttonColor="transparent"
          textColor={colors.Primary.Primary}
          borderColor={colors.Primary.Primary}
          action={clickCancel}
        />
      </S.ButtonsLayout>
    </section>
  );
};

export default Upload;
