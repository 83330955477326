import React, { useState } from 'react';
import * as S from 'shared/styles/index.style';

const Toggle = ({
  isActive,
  setIsActive,
}: {
  isActive: boolean;
  setIsActive: (state: boolean) => void;
}) => {
  const clickToggle = () => {
    setIsActive(!isActive);
  };
  return (
    <S.ToggleBox isActive={isActive} onClick={clickToggle}>
      <S.ToggleCircle isActive={isActive} />
    </S.ToggleBox>
  );
};

export default Toggle;
