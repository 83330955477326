import { colors } from 'shared/consts/color';
import styled from 'styled-components';

export const RepositoriesSection = styled.section``;

export const PageTitleBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DashBordBox = styled.div`
  margin-top: 3em;
`;

export const RepoEntity = styled.ul`
  display: grid;
  grid-template-columns: 0.5fr 2fr 1fr 1fr 1fr 1fr;
  align-items: center;
  margin: 0 3em 1.5em;
`;

export const TitleItem = styled.li`
  text-align: center;

  p {
    font-size: 1rem;
    font-weight: 600;
    color: ${colors.WarmGray[400]};
  }
`;

export const ButtonBox = styled.div`
  display: flex;
  column-gap: 1.5em;
`;

export const RepositoryListBox = styled.div`
  background-color: ${colors.WarmGray[25]};
  border-radius: 1.25em;
  padding: 1.5em 0;

  ul:last-child {
    margin-bottom: 0;
  }
`;
