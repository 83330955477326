import { colors } from 'shared/consts/color';
import styled from 'styled-components';
import { BasicRoundButton } from '../widget/BasicRoundButton.style';

export const NewRepositorySection = styled.section`
  height: calc(100vh - 8em);
  position: relative;
`;

export const UploadBox = styled.div`
  width: 100%;
  margin-top: 3em;
`;

export const UploadList = styled.ul`
  background-color: ${colors.WarmGray[25]};
  border-radius: 1.5em;
  padding: 3em;

  p {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 2rem;
  }
`;

export const PreviewBoxModel = styled.div`
  background-color: ${colors.WarmGray[200]};
  border-radius: 8px;
`;

export const PreviewBoxPartialModel = styled.div`
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
  background-color: ${colors.WarmGray[200]};
`;
export const PreviewBoxOption = styled.div`
  margin-top: 1.5em;
  position: relative;
`;

export const ButtonsLayout = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const PreviewBox = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  column-gap: 1em;
  width: 100%;
  height: 26.5em;

  /* ${PreviewBoxModel} {
    grid-row: 1 / 4;
    margin-right: 2em;
  }
  ${PreviewBoxOption} {
    grid-column: 2 / 5;
    grid-template-rows: 1fr 1fr;
  }
  ${ButtonsLayout} {
    grid-column: 2 / 5;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  ${BasicRoundButton} {
    margin: 0;
    width: 49.5%;
  } */
`;
