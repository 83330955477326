import { repoAtom } from './../../../app/jotai/repoState';
import { useAtom } from 'jotai';
import React from 'react';

const useSaveRepo = () => {
  const [repoList, setRepoList] = useAtom(repoAtom);

  const saveRepo = (title: string, type: string) => {
    const uniqueId = Math.floor(Math.random() * 1000000000);
    setRepoList([
      ...repoList,
      {
        createdAt: new Date().toISOString(),
        id: uniqueId,
        isPaid: false,
        isVerified: false,
        title,
        type,
      },
    ]);
  };

  return { repoList, saveRepo };
};

export default useSaveRepo;
