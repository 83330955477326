import React from 'react';
import * as S from 'shared/styles/index.style';

const BasicButton = ({
  name,
  size,
  action,
  position,
  icon,
  buttonColor,
  textColor,
  borderColor,
}: {
  name?: string;
  size: string;
  action: any;
  position?: string;
  icon?: string;
  buttonColor?: string;
  textColor?: string;
  borderColor?: string;
}) => {
  if (position === 'absolute') {
    return (
      <S.AbsoluteBasicButton
        size={size}
        onClick={action}
        buttonColor={buttonColor}
        $textColor={textColor}
        $borderColor={borderColor}
      >
        {icon && <img src={icon} />}
        {name}
      </S.AbsoluteBasicButton>
    );
  }
  return (
    <S.BasicButton
      size={size}
      onClick={action}
      buttonColor={buttonColor}
      $textColor={textColor}
      $borderColor={borderColor}
    >
      {name}
    </S.BasicButton>
  );
};

export default BasicButton;
