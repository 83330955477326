import React from 'react';
import { modelAtom } from 'app/jotai/modelState';
import { useAtomValue } from 'jotai';
import * as S from 'shared/styles/index.style';
import CanvasLayout from 'widgets/3dModel/CanvasLayout';
import TeethModel from 'widgets/3dModel/TeethModel';

const PartialModel = ({
  title,
  path,
  path2,
}: {
  title: string;
  path: string;
  path2?: string;
}) => {
  const modelState = useAtomValue(modelAtom);
  const position = modelState.type === 'implant' ? [5, 0, 15] : [0, 0, 0];
  return (
    <S.PreviewBoxPartialModel>
      <S.ModelBoxTitle>{title}</S.ModelBoxTitle>
      <CanvasLayout>
        <TeethModel path={path} position={position} />
        {modelState?.type === 'implant' && title === 'abutment' ? (
          <TeethModel path={'implant/abutment2'} position={position} />
        ) : (
          <></>
        )}
        {path2 ? <TeethModel path={path2} /> : <></>}
      </CanvasLayout>
    </S.PreviewBoxPartialModel>
  );
};

export default PartialModel;
