import React, { ReactElement } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';

const CanvasLayout = ({
  children,
}: {
  children: ReactElement[] | ReactElement;
}) => {
  return (
    <Canvas frameloop="demand" camera={{ position: [30, 30, 30], fov: 30 }}>
      <ambientLight intensity={Math.PI / 3} />
      <spotLight
        position={[10, 25, 10]}
        angle={0.15}
        penumbra={1}
        decay={0}
        intensity={Math.PI}
      />
      <directionalLight position={[10, 5, -30]} color="white" />
      <pointLight position={[15, 20, 20]} decay={0} intensity={Math.PI} />
      <OrbitControls />
      {children}
    </Canvas>
  );
};

export default CanvasLayout;
