import React from 'react';
import Title from 'widgets/title/PageTitle';
import * as S from 'shared/styles/index.style';
import Upload from './UI/Upload';

const Repository = () => {
  return (
    <S.NewRepositorySection>
      <Title name="New Repository" />
      <S.UploadBox>
        <S.UploadList>
          <Upload />
        </S.UploadList>
      </S.UploadBox>
    </S.NewRepositorySection>
  );
};

export default Repository;
