export const saveToken = (accessToken: string, refreshToken: string) => {
  // accessToken은 localStorage에 저장, refreshToken은 httponly cookie에 저장
  window.localStorage.setItem('accessToken', accessToken);
  document.cookie = `refreshToken=${refreshToken}`;
};

export const removeToken = () => {
  window.localStorage.removeItem('accessToken'); // localStorgae에 있는 accessToken 삭제
  document.cookie =
    'refreshToken' + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'; // 만료시간을 과거로해서 쿠키의 refreshToken 삭제
};
