import React, { ReactElement } from 'react';
import * as S from 'shared/styles/index.style';
import { LayoutPropsType } from 'LayoutType';

type ModalLayoutProps = {
  setIsModalOpen: (state: boolean) => void;
  title: string;
  children: ReactElement;
};

const ModalLayout = ({ setIsModalOpen, title, children }: ModalLayoutProps) => {
  return (
    <>
      <S.ModalBackGround onClick={() => setIsModalOpen(false)} />
      <S.LargeModalBox>
        <S.ModalBoxTitle>{title}</S.ModalBoxTitle>
        <S.ModalContent>{children}</S.ModalContent>
      </S.LargeModalBox>
    </>
  );
};

export default ModalLayout;
