import { colors } from 'shared/consts/color';
import styled from 'styled-components';

export const DropBox = styled.div`
  /* width: 100%;
  height: 100%; */
  border: 2px dotted ${colors.Primary.Primary};
  border-radius: 1.5em;
  background-color: rgba(230, 246, 249, 0.6);
  padding: 3em 0;
  text-align: center;
  margin-bottom: 1.5em;
`;

export const DropBoxVerification = styled.div`
  width: 50%;
  height: 100%;
  border: 2px dotted ${colors.Primary.Primary};
  border-radius: 1.5em;
  background-color: rgba(230, 246, 249, 0.6);
  padding: 3em 0;
  text-align: center;
  margin-bottom: 1.5em;
`;

export const SmallDropboxContainer = styled.div`
  width: 49%;
  margin-bottom: 5vh;
  background-color: ${colors.WarmGray[25]};
  padding: 20px;
  border-radius: 10px;
`;

export const DropboxTitle = styled.p`
  font-size: 1.25em;
  font-weight: bold;
  margin-bottom: 1em;
`;

export const DropBoxList = styled.ul`
  li:last-child {
    margin-bottom: 0;
  }
`;

export const DropBoxItem = styled.li`
  margin-bottom: 0.875em;

  .selectFileBtn {
    width: 15em;
    border: none;
    border-radius: 8px;
    padding: 0.5em 1em;
    margin: 0 auto;
    background-color: ${colors.Primary.Primary};
    color: ${colors.White};
    font-size: 0.75em;
    cursor: pointer;
  }

  img {
    height: 6.75em;
  }

  span {
    display: block;
    font-size: 1.25em;
    font-weight: 400;
    color: ${colors.WarmGray[400]};
  }

  input {
    display: none;
  }
`;

export const UploadingBox = styled.div`
  border: 1px solid ${colors.WarmGray[300]};
  border-radius: 0.625em;
  padding: 1.5em;
  background-color: ${colors.White};

  p {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
`;

export const UploadingList = styled.ul`
  display: flex;
  align-items: center;

  .uploadingFileIcon {
    height: 4em;
    margin-right: 0.875em;
  }

  .fileIcon {
    height: 1.5em;
    margin-right: 0.25em;
  }

  li:last-child {
    width: 100%;
  }
`;

export const UploadingItem = styled.li`
  display: flex;
  align-items: center;

  .queueText {
    font-size: 1em;
    font-weight: 400;
    color: ${colors.WarmGray[300]};
  }
`;

export const ProgressList = styled.ul`
  width: 100%;
`;

export const ProgressItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;

  .fileName {
    font-size: 1em;
    font-weight: 600;
    margin-right: 0.5em;
  }
  .percentage {
    font-size: 0.75em;
    font-weight: 600;
    color: ${colors.WarmGray[400]};
  }
  .fileSize {
    font-size: 0.75em;
    font-weight: 600;
    color: ${colors.WarmGray[400]};
  }

  img {
    height: 1.5em;
    margin: 0;
  }

  progress {
    inline-size: 100%;
    appearance: none;
    display: block;
    margin-right: 0.5em;
  }
  progress::-webkit-progress-bar {
    background-color: ${colors.Primary[100]};
    border-radius: 10px;
  }
  progress::-webkit-progress-value {
    background-color: ${colors.Primary.Primary};
    border-radius: 10px;
  }
`;
