import React from 'react';
import * as S from 'shared/styles/index.style';
import { MailIconComponent, PasswordIconComponent } from 'assets/icon';
import FormInput from 'widgets/Form/FormInput';
import useInputs from 'pages/signup/model/useInputs';
import { userInputData } from './consts/userInputData';
import SubmitButton from './UI/SubmitButton';
import useFormHandler from './model/useFormHandler';
import FormHeader from './UI/FormHeader';

const LoginPage = () => {
  const [userInput, inputHandler] = useInputs(userInputData);
  const { formHandler } = useFormHandler(userInput);

  return (
    <S.FormBox onSubmit={formHandler}>
      <FormHeader />
      <FormInput
        icon={<MailIconComponent />}
        placeholder="Email"
        name="email"
        maxLength={30}
        inputHandler={inputHandler}
      />
      <S.FormErrorMessage>{''}</S.FormErrorMessage>
      <FormInput
        icon={<PasswordIconComponent />}
        placeholder="Password"
        name="password"
        type="password"
        maxLength={20}
        inputHandler={inputHandler}
      />
      <S.FormErrorMessage>{''}</S.FormErrorMessage>
      <SubmitButton />
    </S.FormBox>
  );
};

export default LoginPage;
