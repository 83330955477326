import { modelAtom } from 'app/jotai/modelState';
import { useAtomValue } from 'jotai';
import React from 'react';
import CanvasLayout from 'widgets/3dModel/CanvasLayout';
import ModelBoxLayout from 'widgets/3dModel/ModelBoxLayout';
import TeethModel from 'widgets/3dModel/TeethModel';

const ReferenceData = () => {
  const modelState = useAtomValue(modelAtom);

  return (
    <ModelBoxLayout title="Reference Data">
      <CanvasLayout>
        {modelState.type === 'denture' ? (
          <>
            <TeethModel
              path={`${modelState.type}/upperteeth`}
              position={[0, -42, 60]}
              rotation={[0, 9.4, 9.5]}
            />
            <TeethModel
              path={`${modelState.type}/lowerteeth`}
              position={[0, 0, -10]}
            />
          </>
        ) : (
          <TeethModel path={`${modelState.type}/object`} />
        )}
      </CanvasLayout>
    </ModelBoxLayout>
  );
};

export default ReferenceData;
