import React from 'react';
import * as S from 'shared/styles/index.style';
import { ArrowDownIcon, ArrowUpIcon } from 'assets/icon';

const SelectBox = ({
  isToggleOpen,
  setIsToggleOpen,
  type,
  setType,
}: {
  isToggleOpen: boolean;
  setIsToggleOpen: (state: boolean) => void;
  type: string;
  setType: (state: string) => void;
}) => {
  const onClickOption = (e: any) => {
    setType(e.target.id);
    setIsToggleOpen(false);
  };
  const arrowIcon = isToggleOpen ? ArrowUpIcon : ArrowDownIcon;
  return (
    <S.SelectBoxLayout>
      <S.SelectBox onClick={() => setIsToggleOpen(!isToggleOpen)}>
        <span>{type}</span>
        <img src={arrowIcon} />
      </S.SelectBox>
      {isToggleOpen ? (
        <S.SelectOption onClick={onClickOption}>
          <li id="Crown">Crown</li>
          <li id="Implant">Implant</li>
          <li id="Denture">Denture</li>
          <li id="Laminate">Laminate</li>
          <li id="Orthodontics">Orthodontics</li>
        </S.SelectOption>
      ) : undefined}
    </S.SelectBoxLayout>
  );
};

export default SelectBox;
