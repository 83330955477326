import React, { useState } from 'react';
import * as S from 'shared/styles/index.style';
import { STLFileIconSmall } from 'assets/icon';
import UploadingBox from './UploadingBox';
import { useAtom } from 'jotai';
import { fileAtom } from 'app/jotai/fileState';

export default function SmallDropBox(dropBoxProps: {
  id?: string;
  contentModel: File | undefined;
  setContentModel: (file: File | File[]) => void;
}) {
  const [__, setIsDragging] = useState(false);
  const [_, setFilesState] = useAtom(fileAtom);
  const { id, contentModel, setContentModel } = dropBoxProps;

  const handleImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files && files.length > 0) {
      setContentModel([...files]);
      setFilesState(new Array(files.length).fill(false));
    }
    e.target.value = ''; // After updating, reset the value to allow the same file to be selected again if needed.
  };

  const onDragEnter = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };
  const onDragLeave = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };
  const onDragOver = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files) {
      setIsDragging(true);
    }
  };
  const onDrop = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setContentModel(e.dataTransfer.files[0]);
    setIsDragging(false);
  };
  return (
    <>
      <S.DropBox
        onDragEnter={onDragEnter} // 드래그된 요소가 드롭 대상에 들어갈 때 발생
        onDragLeave={onDragLeave} // 드래그된 요소가 드롭 대상을 벗어날 때 발생
        onDragOver={onDragOver} // 발생 드래그된 요소가 드롭 대상 위에 있을 때 발생
        onDrop={onDrop} // 드래그한 요소를 드롭 대상에 놓을 때 발생
      >
        <S.DropBoxList>
          <S.DropBoxItem>
            <img src={STLFileIconSmall} />
          </S.DropBoxItem>
          <S.DropBoxItem>
            <span>Drag and Drop Files to upload</span>
            <span>or</span>
          </S.DropBoxItem>
          <S.DropBoxItem>
            <label htmlFor={id}>
              <span className="selectFileBtn">Select File to upload</span>
            </label>
            <input
              accept=".stl"
              type="file"
              id={id}
              onChange={handleImage} // multiple
            />
          </S.DropBoxItem>
        </S.DropBoxList>
      </S.DropBox>
      <UploadingBox Files={contentModel} />
    </>
  );
}
