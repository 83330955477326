import React, { ReactElement } from 'react';
import * as S from 'shared/styles/index.style';

const OptionLayout = ({
  children,
}: {
  children: ReactElement[] | ReactElement;
}) => {
  return <S.OptionMenuContainer>{children}</S.OptionMenuContainer>;
};

export default OptionLayout;
