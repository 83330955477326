import React from 'react';
import BasicCheckBox from 'widgets/button/BasicCheckBox';
import FieldName from './FieldName';
import * as S from 'shared/styles/index.style';
import { useAtom, useAtomValue } from 'jotai';
import { checkedReposAtom } from 'app/jotai/checkedRepoState';
import { repoAtom } from 'app/jotai/repoState';

const RepoEntity = ({
  checkedControl,
}: {
  checkedControl: {
    isAllRepoChecked: boolean;
    setIsAllRepoChecked: (state: boolean) => void;
  };
}) => {
  const repoList = useAtomValue(repoAtom);

  const { isAllRepoChecked, setIsAllRepoChecked } = checkedControl;
  const [_, setCheckedRepo] = useAtom(checkedReposAtom);
  const checkAllRepo = () => {
    setIsAllRepoChecked(!isAllRepoChecked);
    if (isAllRepoChecked) {
      setCheckedRepo([]);
    } else {
      setCheckedRepo(repoList.map(obj => obj.id));
    }
  };
  return (
    <S.RepoEntity>
      <BasicCheckBox isChecked={isAllRepoChecked} checkFunc={checkAllRepo} />
      <FieldName name="Name" />
      <FieldName name="Date created" />
      <FieldName name="Verification" />
      <FieldName name="Payment" />
    </S.RepoEntity>
  );
};

export default RepoEntity;
