import React from 'react';
import { Outlet } from 'react-router-dom';
import NavigationHeader from 'widgets/navigation/NavigationHeader';

const MainLayout = () => {
  return (
    <>
      <NavigationHeader />
      <Outlet />
    </>
  );
};

export default MainLayout;
