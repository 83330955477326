import { styled } from 'styled-components';
export const LoadingIndicatorContainer = styled.div`
  position: fixed;
  z-index: 99999;
  top: 50%;
  left: 50%;
  color: white;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
`;

export const LoadingIndicatorMessage = styled.div`
  transform: translateY(100%);
  font-weight: bold;
`;
