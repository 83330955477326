import { colors } from 'shared/consts/color';
import { styled } from 'styled-components';
export const ModalBackGround = styled.div`
  position: fixed;
  background-color: ${colors.WarmGray[900]};
  backdrop-filter: blur(5px);
  top: 0;
  left: 0;
  opacity: 25%;
  z-index: 9999;
  height: 100vh;
  width: 100vw;
`;
export const LargeModalBox = styled.div`
  position: fixed;
  z-index: 99999;
  width: 75em;
  height: 36.75em;
  background-color: ${colors.WarmGray[25]};
  border-radius: 24px;
  left: calc((100vw - 75em) / 2);
  top: calc((100vh - 36.75em) / 2);
`;

export const SmallModalBox = styled(LargeModalBox)`
  width: 37.5em;
  height: 21em;
  left: calc((100vw - 37.5em) / 2);
  top: calc((100vh - 21em) / 2);
`;

export const ModalInputContainer = styled.div`
  width: 31.5em;
  display: flex;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  input {
    width: 23.5rem;
    height: 2rem;
    border: 1px solid ${colors.WarmGray[200]};
    border-radius: 12px;
    font-size: 1rem;
    padding: 0.75em;
  }
`;

export const ModalInputTitle = styled.div`
  width: 10rem;
  font-size: 1rem;
  line-height: 3.5rem;
  font-weight: bold;
`;

export const ModalBoxTitle = styled.h1`
  position: absolute;
  z-index: 999;
  left: 0;
  right: 0;
  margin: auto;
  color: ${colors.Primary.Primary};
  font-size: 2.5em;
  font-weight: bold;
  text-align: center;
  margin-top: 0.5em;
`;

export const SmallModalBoxTitle = styled(ModalBoxTitle)`
  position: static;
`;

export const ModalContent = styled.div`
  position: absolute;
  border-radius: 24px;
  top: 0;
  width: 75em;
  height: 36.75em;
`;

export const SmallModalContent = styled.div`
  position: static;
  border-radius: 24px;
  top: 0;
  margin: 0 3rem 0 3rem;
`;

export const VerificationModalGrid = styled.div`
  position: absolute;
  top: 5em;
  left: 5em;
  right: 0;
  margin: auto;
  display: grid;
  grid-template-columns: 2fr 3fr 1fr;
  grid-template-rows: 2fr 1fr 1fr;
`;

export const ModalButtonLayout = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;

  button {
    width: 6em;
  }
`;
