import styled from 'styled-components';
import { colors } from 'shared/consts/color';

export const ProfileContainer = styled.div`
  display: flex;
  width: 100%;
  height: 5.25rem;
  align-items: center;
  z-index: 999;
  border-top: 1px solid ${colors.WarmGray[300]};
  padding: 2em;
  p {
    font-weight: 400;
    padding-left: 0.5rem;
    font-size: 1.25em;
  }
`;
