import React from 'react';
import SmallDropBox from 'widgets/dropBox/SmallDropBox';
import VerificationDropBoxContainer from 'widgets/dropBox/VerificationDropBoxContainer';

const UploadSection = ({
  outerScanModel,
  setOuterScanModel,
  innerScanModel,
  setInnerScanModel,
}: {
  outerScanModel: File | undefined;
  setOuterScanModel: (file: File | File[]) => void;
  innerScanModel: File | undefined;
  setInnerScanModel: (file: File | File[]) => void;
}) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <VerificationDropBoxContainer title="Outer Scan Data">
        <SmallDropBox
          id="outer"
          contentModel={outerScanModel}
          setContentModel={setOuterScanModel}
        />
      </VerificationDropBoxContainer>
      <VerificationDropBoxContainer title="Inner Scan Data">
        <SmallDropBox
          id="inner"
          contentModel={innerScanModel}
          setContentModel={setInnerScanModel}
        />
      </VerificationDropBoxContainer>
    </div>
  );
};

export default UploadSection;
